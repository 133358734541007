import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import ListSelectBox from "../../components/ListSelectBox";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/locale";
import DatePickerInput from "../../components/DatePickerInput";
import ReportUpperTable from "../../components/Table/ReportUpperTable";
import ReportLowerTable from "../../components/Table/ReportLowerTable";
import Pagination from "../../components/Pagination/Pagination";
import {searchOption} from "../storePage/CustomerReportPage";
import {roundDefaultList, roundInfo} from "../ticketListPage/base/BaseTicketListPage";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo, lastMonth, lastWeek, today, yesterday} from "../../constants/constants";
import {
  ADMIN_AGGREGATE_TABLE_LIST_API, ADMIN_AGGREGATE_TICKET_LIST_API,
  ADMIN_AGGREGATE_TOTAL_API,
} from "../../constants/api_constants";
import {toast} from "react-toastify";
import ListSelectBoxWithDefaultData from "../../components/ListSelectBoxWithDefaultData";
import {FOLDER_OPTION, REPORT_UPPER_TABLE_HEADER, searchTypeOption} from "../../constants/component_data";
import SelectStoreModal from "../../components/Modal/SelectStoreModal";
import ReportUpperCard from "../../components/Card/ReportUpperCard";
import ReportLowerCard from "../../components/Card/ReportLowerCard";
import {RiFileListLine, RiFilePaper2Line} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import BaseTicketCard from "../../components/Card/BaseTicketCard";
import neverLandGameData from "../../utils/NeverLandGameData";
import SelectServiceModal from "../../components/Modal/SelectServiceModal";

const requestStatus = [
  {name: '승인건전체', value: 'status5'},
  {name: '요청승인', value: 'status2'},
  {name: '내부승인', value: 'status4'},
  {name: '출력', value: 'status3'},
  {name: '승인 + 출력', value: 'status1'},
  {name: '의뢰', value: 'status6'},
  {name: '취소', value: 'status7'},
  {name: '요청취소', value: 'status8'},
]

const AdminReportPage = () => {
  const [showSelectStoreModal, setShowSelectStoreModal] = useState(false);
  const [selectedStoreList, setSelectedStoreList] = useState([]); // 선택한 지점 리스트
  const [showSelectedStoreList, setShowSelectedStoreList] = useState(false); // 선택한 지점 리스트 띄우기

  // 임시
  const [showSelectServiceModal, setShowSelectServiceModal] = useState(false);
  const [selectedServiceList, setSelectedServiceList] = useState([]); // 선택한 지점 리스트
  const [showSelectedServiceList, setShowSelectedServiceList] = useState(false); // 선택한 지점 리스트 띄우기
  const [searchServiceNumber, setSearchServiceNumber] = useState(''); // 서비스 코드 검색어


  const [status, setStatus] = useState(requestStatus[0]); // 의뢰 상태
  const [folder, setFolder] = useState(FOLDER_OPTION[0]); // 폴더
  const [isFilterRound, setIsFilterRound] = useState(true);  // 조회조건 : 회차 || 날짜
  const [selectedRound, setSelectedRound] = useState(roundDefaultList[0]); // 회차
  const [startDate, setStartDate] = useState(new Date()); // DatePicker
  const [endDate, setEndDate] = useState(new Date());
  const [searchNickname, setSearchNickname] = useState(''); // 닉네임 검색어
  const [searchMemo, setSearchMemo] = useState(''); // 메모 검색어
  const [searchGameNumber, setSearchGameNumber] = useState(''); // 경기번호 검색어
  const [searchHomeTeam, setSearchHomeTeam] = useState(''); // 홈팀 검색어
  const [searchRequestNumber, setSearchRequestNumber] = useState(''); // 의뢰번호 검색어
  const [searchType, setSearchType] = useState(searchTypeOption[0])  // 검색 조건 (일치, 포함)
  const [isTicketShowMode, setIsTicketShowMode] = useState(false);  // 티켓형식/리스트형식으로 보기

  const [upperTableBody, setUpperTableBody] = useState({});
  const [lowerTableBody, setLowerTableBody] = useState([]);
  const [lowerTicketDataList, setLowerTicketDataList] = useState([]);

  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [alignInfo, setAlignInfo] = useState('');
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = 30;

  const dateFilterButton = [
    {label: '당일', onClick: () => handleDateChange(today, today)},
    {label: '전일', onClick: () => handleDateChange(yesterday, yesterday)},
    {label: '일주일', onClick: () => handleDateChange(lastWeek, today)},
    {label: '한달', onClick: () => handleDateChange(lastMonth, today)},
  ]

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  function _getFormatDate() {
    let _startDate = neverLandUtils.utils.getDisplayTextByDate(startDate);
    let _endDate = neverLandUtils.utils.getDisplayTextByDate(endDate);
    return _startDate + '-' + _endDate;
  }

  useEffect(() => {
    if (!isFirstLoading) {
      getTableData();
      setIsFirstLoading(true);
    }
  }, [isTicketShowMode]);

  useEffect(() => {
    if (isFirstLoading)
      getLowerTableBodyData();
  }, [currentPage, alignInfo]);


  const getTableData = () => {
    try {
      getUpperTableBodyData();
    } catch {
    }

    try {
      if (isTicketShowMode) {
        getTicketDataList();
      } else {
        getLowerTableBodyData();
      }
    } catch {
    }
  }

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    let jsonString = Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
    return jsonString;
  }

  const handleSearchOptionSelect = (selectOption) => {
    // <-- 기간검색 || 회차검색 -->
    if (selectOption.id === 'round') {
      setIsFilterRound(true);
      // 회차 검색이면 조회날짜 오늘
    } else {
      setIsFilterRound(false);
      // 기간 검색이면 현재 회차
      setSelectedRound(roundDefaultList[0]);
    }
  }

  function _listToScript(list) {
    let script = "";
    for (let s of list) {
      if (script.length > 0) {
        script += "♩";
      }
      script += s.storeName;
    }
    return script;
  }

  function _listToServiceScript(serviceList) {
    let script = "";
    for (let s of serviceList) {
      if (script.length > 0) {
        script += "♩";
      }
      script += s.serviceCode;
    }
    return script;
  }

  const getSearchCondition = () => {
    // <-- 검색조건 -->
    let _memo;
    let _nickName;
    if (searchType.name === '정확히 일치') {
      _memo = "★" + searchMemo + "★"
      _nickName = "★" + searchNickname + "★"
    } else if (searchType.name === '포함') {
      _memo = searchMemo
      _nickName = searchNickname
    }

    let _searchCondition;
    if (isFilterRound) {
      _searchCondition = `status:${status.value}` + '▼' + `nickname:${_nickName}` + '▼' + `memo:${_memo}` + '▼'
          + `gameNumber:${searchGameNumber}` + '▼' + `homeTeam:${searchHomeTeam}` + '▼' + `requestNumber:${searchRequestNumber}` + '▼' + `folder:${folder.id}`;
    } else {
      _searchCondition = `status:${status.value}` + '▼' + `date:${_getFormatDate()}` + '▼' + `nickname:${_nickName}` + '▼' + `memo:${_memo}` + '▼'
          + `gameNumber:${searchGameNumber}` + '▼' + `homeTeam:${searchHomeTeam}` + '▼' + `requestNumber:${searchRequestNumber}` + '▼' + `folder:${folder.id}`;
    }

    let _selectedStoreList = selectedStoreList;
    if (selectedStoreList.length > 0) {
      _searchCondition += `▼storenames:${_listToScript(_selectedStoreList)}`;
    }

    let _selectedServiceList = selectedServiceList;
    if (selectedServiceList.length > 0) {
      _searchCondition += `▼serviceCode:${_listToServiceScript(_selectedServiceList)}`;
    }

    return _searchCondition;
  }

  const getGameRound = (roundData) => {
    // 회차정보
    if (roundData === 'current') return "";
    let round = roundData.substring(7);
    return roundData.substring(5, 7) + "년도 " + ((round.startsWith('0') ? round.substring(1) : round)) + " 회차";
  }

  const getUpperTableBodyData = () => {
    // <-- 상단 집계 테이블 데이터 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_AGGREGATE_TOTAL_API)
        + `&roundCode=${selectedRound.data.code}&searchCondition=${encodeURI(getSearchCondition())}`
    ).then(res => {
      const {header, result, custom} = res.data;
      if (header.type === 'success') {
        setUpperTableBody(result.total);

        if (custom !== undefined && custom.items !== undefined && custom.items.length > 0) {
          let tempName;
          for (let item of custom.items) {
            // 데이터에서 회차 셀렉트 리스트 받아와서 설정
            if (item.key === 'rounds') {
              let newRoundList = [];
              newRoundList.push(roundDefaultList[0]);

              let index = 2;
              for (let round of item.value) {
                tempName = getGameRound(round).trim();
                if (tempName.length === 0) continue;
                newRoundList.push({id: index++, name: tempName, data: {code: round}});
              }
              if (newRoundList.length > 0) {
                roundInfo.list = newRoundList;
                setSelectedRound(newRoundList[1]);
              }
            }
          }
        }
      } else toast.error(header.message);
    }).catch(err => toast.error(err));
  }

  const getLowerTableBodyData = () => {
    // <-- 하단 리스트 테이블 데이터 가져오기 -->
    let localAlignInfo = alignInfo;
    if (!isFirstLoading) {
      localAlignInfo = jsonDataToString(accountInfo.getAlignDataList("ReportLowerTable"));
    }
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_AGGREGATE_TABLE_LIST_API)
        + `&roundCode=${selectedRound.data.code}&searchCondition=${getSearchCondition()}&pageNumber=${currentPage}&pageSize=${pageSize}&searchOrder='${localAlignInfo}`
    ).then(res => {
      const {header, result} = res.data;
      if (header.type === 'success') {
        let _list = result.list;

        let currentPageNumber = _list.pageable.pageNumber + 1;
        if (_list.content.length === 0) {
          currentPageNumber = 1;
        }
        if (currentPage !== currentPageNumber) setCurrentPage(currentPageNumber);
        setTotalPage(_list.totalPages);
        setLowerTableBody(_list.content);
      } else toast.error(header.message);
    }).catch(err => toast.error(err));
  }

  const getTicketDataList = () => {
    // <-- 하단 티켓형식 데이터 가져오기 -->
    let localAlignInfo = alignInfo;
    if (!isFirstLoading) {
      localAlignInfo = jsonDataToString(accountInfo.getAlignDataList("ReportLowerTable"));
    }
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_AGGREGATE_TICKET_LIST_API)
        + `&roundCode=${selectedRound.data.code}&searchCondition=${getSearchCondition()}&pageNumber=${currentPage}&pageSize=${pageSize}&searchOrder='${localAlignInfo}`
    ).then(res => {
      const {header, result} = res.data;
      if (header.type === 'success') {
        let datalist = neverLandGameData.convertGameDataToTicket(result.gameDataList.content);
        let currentPageNumber = result.gameDataList.pageable.pageNumber + 1;
        if (datalist.length === 0) {
          currentPageNumber = 1;
        }
        if (currentPage !== currentPageNumber) setCurrentPage(currentPageNumber);
        setTotalPage(result.gameDataList.totalPages);
        setLowerTicketDataList(datalist);
      }
    }).catch(err => toast.error(err));
  }

  return (
      <>
        {showSelectStoreModal &&
            <SelectStoreModal selectedStoreList={selectedStoreList} setSelectedStoreList={setSelectedStoreList}
                              setShowSelectStoreModal={setShowSelectStoreModal}/>}
        {/* 임시: 서비스코드 모달 추가*/}
        {showSelectServiceModal &&
            <SelectServiceModal selectedServiceList={selectedServiceList}
                                setSelectedServiceList={setSelectedServiceList}
                                setShowSelectServiceModal={setShowSelectServiceModal}/>}
        <div>
          <Sidebar/>
          <div className="lg:pl-64">
            <AdminPageHeader/>
            <main>
              <div className="h-full border-t border-white/10 py-2 lg:py-12">
                <div className="sm:-mx-0 sm:rounded-lg">
                  {/* <-- 검색조건 라인 --> */}
                  {/* <-- PC 사이즈 --> */}
                  <div className="hidden xl:flex mx-10 my-8 flex-wrap flex-row justify-start text-sm items-center">
                    {/* 임시: PC용 서비스 선택버튼 추가 */}
                    <div className="relative">
                      <button
                          onClick={() => setShowSelectServiceModal(true)}
                          onMouseOver={() => setShowSelectedServiceList(true)}
                          onMouseLeave={() => setShowSelectedServiceList(false)}
                          className="whitespace-nowrap rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 mr-2 mt-2 lg:mt-0">
                        {selectedServiceList.length === 0 ? "서비스 선택" : (selectedServiceList.length === 1 ?
                            selectedServiceList[0].serviceName : selectedServiceList.length + "개 서비스선택")}
                      </button>
                      <div className={`z-10 absolute top-10 left-1/2 ${showSelectedServiceList ? 'block' : 'hidden'}`}>
                            <span
                                className="whitespace-nowrap w-full max-w-xs border border-solid border-sky-200 rounded p-2 text-sm bg-sky-200 font-semibold">
                              {selectedServiceList.length === 0 ? "전체 서비스"
                                  : selectedServiceList.map((service, index) => (
                                      <span key={index}>
                                        {service.serviceName}
                                        {index < selectedServiceList.length - 1 && ', '}
                                      </span>
                                  ))
                              }
                            < /span>
                      </div>
                    </div>
                    <div className="relative">
                      <button
                          onClick={() => setShowSelectStoreModal(true)}
                          onMouseOver={() => setShowSelectedStoreList(true)}
                          onMouseLeave={() => setShowSelectedStoreList(false)}
                          className="whitespace-nowrap rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 mr-2 mt-2 lg:mt-0">
                        {selectedStoreList.length === 0 ? "전체 지점" : (selectedStoreList.length === 1 ? selectedStoreList[0].storeName : selectedStoreList.length + " 지점선택")}
                      </button>
                      <div className={`z-10 absolute top-10 left-1/2 ${showSelectedStoreList ? 'block' : 'hidden'}`}>
                            <span
                                className="whitespace-nowrap w-full max-w-xs border border-solid border-sky-200 rounded p-2 text-sm bg-sky-200 font-semibold">
                              {selectedStoreList.length === 0 ? "전체 지점"
                                  : selectedStoreList.map((store, index) => (
                                      <span key={index}>
                                        {store.storeName}
                                        {index < selectedStoreList.length - 1 && ', '}
                                      </span>
                                  ))
                              }
                            < /span>
                      </div>
                    </div>
                    <ListSelectBox listData={requestStatus} onChange={setStatus}/>
                    <ListSelectBox listData={FOLDER_OPTION} onChange={setFolder}/>
                    <ListSelectBox listData={searchOption} onChange={handleSearchOptionSelect}/>
                    {isFilterRound && (
                        // 회차검색
                        <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                                      onChange={setSelectedRound}/>
                    )}
                    {!isFilterRound && (
                        // 기간검색
                        <div className="flex flex-row items-center">
                          {dateFilterButton.map((data) => (
                              <button
                                  key={data.label}
                                  onClick={data.onClick}
                                  className="whitespace-nowrap mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                              >
                                {data.label}
                              </button>
                          ))}
                          <div
                              className="whitespace-nowrap mr-3 flex flex-row text-sm justify-between px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center">
                            <DatePicker
                                locale={ko}
                                dateFormat='yyyy-MM-dd'
                                selected={startDate}
                                onChange={date => handleDateChange(date, endDate)}
                                customInput={<DatePickerInput/>}
                            />
                            <span className="mx-2"> ~ </span>
                            <DatePicker
                                locale={ko}
                                dateFormat='yyyy-MM-dd'
                                selected={endDate}
                                onChange={date => handleDateChange(startDate, date)}
                                customInput={<DatePickerInput/>}
                            />
                          </div>
                        </div>
                    )}
                    <input
                        placeholder="서비스(일치)"
                        onChange={(e) => setSearchServiceNumber(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2"
                    />
                    <input
                        placeholder="의뢰번호(일치)"
                        onChange={(e) => setSearchRequestNumber(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2"
                    />
                    <input
                        placeholder="경기번호(일치)"
                        onChange={(e) => setSearchGameNumber(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2"
                    />
                    <input
                        placeholder="팀명(포함)"
                        onChange={(e) => setSearchHomeTeam(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2"
                    />
                    <input
                        placeholder={"닉네임(" + searchType.name + ")"}
                        onChange={(e) => setSearchNickname(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-36 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500"
                    />
                    <input
                        placeholder={"메모(" + searchType.name + ")"}
                        onChange={(e) => setSearchMemo(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') getTableData();
                        }}
                        className="mx-1 w-36 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2"
                    />
                    <ListSelectBox listData={searchTypeOption} onChange={setSearchType}/>

                    <button
                        onClick={getTableData}
                        className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                      검색
                    </button>

                    <RiFilePaper2Line onClick={() => setIsTicketShowMode(true)}
                                      className={neverLandUtils.classNames(isTicketShowMode ? 'bg-blue-600' : 'bg-gray-400', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>
                    <RiFileListLine onClick={() => setIsTicketShowMode(false)}
                                    className={neverLandUtils.classNames(isTicketShowMode ? 'bg-gray-400' : 'bg-blue-600', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>
                  </div>

                  {/* <-- 모바일 사이즈 검색 라인 --> */}
                  <div
                      className="xl:hidden lg:mt-3 mx-2 flex flex-wrap flex-row justify-start md:justify-center text-sm items-center">
                    {/* 임시: 모바일 서비스 선택버튼 추가 */}
                    <div className="relative">
                      <button
                          onClick={() => setShowSelectServiceModal(true)}
                          onMouseOver={() => setShowSelectedServiceList(true)}
                          onMouseLeave={() => setShowSelectedServiceList(false)}
                          className="whitespace-nowrap rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm mr-2 mt-2 lg:mt-0">
                        {selectedServiceList.length === 0 ? "서비스 선택" : (selectedServiceList.length === 1 ?
                            selectedServiceList[0].serviceName : selectedServiceList.length + "개 서비스선택")}
                      </button>
                      <div className={`z-10 absolute top-10 left-1/2 ${showSelectedServiceList ? 'block' : 'hidden'}`}>
                            <span
                                className="whitespace-nowrap w-full max-w-xs border border-solid border-sky-200 rounded p-2 text-sm bg-sky-200 font-semibold">
                              {selectedServiceList.length === 0 ? "전체 서비스"
                                  : selectedServiceList.map((service, index) => (
                                      <span key={index}>
                                        {service.serviceName}
                                        {index < selectedServiceList.length - 1 && ', '}
                                      </span>
                                  ))
                              }
                            < /span>
                      </div>
                    </div>
                    <div className="relative">
                      <button
                          onClick={() => setShowSelectStoreModal(true)}
                          onMouseOver={() => setShowSelectedStoreList(true)}
                          onMouseLeave={() => setShowSelectedStoreList(false)}
                          className="whitespace-nowrap rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm mr-2 mt-2 lg:mt-0">
                        {selectedStoreList.length === 0 ? "전체 지점" : (selectedStoreList.length === 1 ? selectedStoreList[0].storeName : selectedStoreList.length + " 지점선택")}
                      </button>
                      <div className={`z-10 absolute top-10 left-1/2 ${showSelectedStoreList ? 'block' : 'hidden'}`}>
                            <span
                                className="whitespace-nowrap w-full max-w-xs border border-solid border-sky-200 rounded p-2 text-sm bg-sky-200 font-semibold">
                              {selectedStoreList.length === 0 ? "전체 지점"
                                  : selectedStoreList.map((store, index) => (
                                      <span key={index}>
                                        {store.storeName}
                                        {index < selectedStoreList.length - 1 && ', '}
                                      </span>
                                  ))
                              }
                            < /span>
                      </div>
                    </div>
                    <ListSelectBox listData={requestStatus} onChange={setStatus}/>
                    <ListSelectBox listData={FOLDER_OPTION} onChange={setFolder}/>
                    <ListSelectBox listData={searchOption} onChange={handleSearchOptionSelect}/>
                    {isFilterRound && (
                        // 회차검색
                        <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                                      onChange={setSelectedRound}/>
                    )}
                    {!isFilterRound && (
                        // 기간검색
                        <div className="flex flex-col md:flex-row items-center mb-0">
                          <div
                              className="whitespace-nowrap mr-3 flex flex-row text-sm justify-between mt-2 mb-1 px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center">
                            <DatePicker
                                locale={ko}
                                dateFormat='yyyy-MM-dd'
                                selected={startDate}
                                onChange={date => handleDateChange(date, endDate)}
                                customInput={<DatePickerInput/>}
                            />
                            <span className="mx-2"> ~ </span>
                            <DatePicker
                                locale={ko}
                                dateFormat='yyyy-MM-dd'
                                selected={endDate}
                                onChange={date => handleDateChange(startDate, date)}
                                customInput={<DatePickerInput/>}
                            />
                          </div>
                        </div>
                    )}
                    <div className="flex flex-col md:flex-row">
                      <div className="flex flex-row">
                        <input
                            placeholder="서비스(일치)"
                            onChange={(e) => setSearchServiceNumber(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-[7.5rem] md:w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                        />
                        <input
                            placeholder="의뢰번호(일치)"
                            onChange={(e) => setSearchRequestNumber(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-[7.5rem] md:w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                        />
                        <input
                            placeholder="경기번호(일치)"
                            onChange={(e) => setSearchGameNumber(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-[7.5rem] md:w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                        />
                        <input
                            placeholder="팀명(포함)"
                            onChange={(e) => setSearchHomeTeam(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-[6.5rem] px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                        />
                      </div>
                      <div className="flex flex-row">
                        <input
                            placeholder={"닉네임(" + searchType.name + ")"}
                            onChange={(e) => setSearchNickname(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-36 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mt-2"
                        />
                        <input
                            placeholder={"메모(" + searchType.name + ")"}
                            onChange={(e) => setSearchMemo(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') getTableData();
                            }}
                            className="mx-1 w-36 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row mt-2 items-center">
                      <ListSelectBox listData={searchTypeOption} onChange={setSearchType}/>
                      <button
                          onClick={getTableData}
                          className="whitespace-nowrap rounded-md bg-blue-900 w-32 md:w-16px-3 py-2 text-sm font-semibold text-white shadow-sm">
                        검색
                      </button>
                      <RiFilePaper2Line onClick={() => setIsTicketShowMode(true)}
                                        className={neverLandUtils.classNames(isTicketShowMode ? 'bg-blue-600' : 'bg-gray-400', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>
                      <RiFileListLine onClick={() => setIsTicketShowMode(false)}
                                      className={neverLandUtils.classNames(isTicketShowMode ? 'bg-gray-400' : 'bg-blue-600', 'h-10 w-10 text-white rounded-full px-2 ml-3')}/>
                    </div>
                  </div>

                  {/* <-- PC 화면 테이블 형식 --> */}
                  <div className="hidden xl:block">
                    {/* <-- 위쪽 테이블 --> */}
                    <ReportUpperTable upperTableBodyData={upperTableBody} tableHeader={REPORT_UPPER_TABLE_HEADER}/>
                    {/* <-- 아래쪽 테이블 --> */}
                    {isTicketShowMode ? (true ?
                            <div className="text-lg mt-20 ml-20 text-indigo-500 font-semibold">※ 기능 구현 중입니다.(2024-06-24~28
                              예정)</div> :
                            <BaseTicketCard gameDataList={lowerTicketDataList}/>)
                        : <ReportLowerTable lowerTableBodyData={lowerTableBody}
                                            setAlignData={(alignInfo) => setAlignInfo(alignInfo)}/>
                    }
                  </div>

                  {/* <-- 화면 작을 때 카드형식 --> */}
                  <div className="xl:hidden flex flex-col justify-center">
                    <ReportUpperCard upperTableBodyData={upperTableBody} tableHeader={REPORT_UPPER_TABLE_HEADER}/>
                  </div>
                  <div className="xl:hidden flex justify-center">
                    <ReportLowerCard lowerTableBodyData={lowerTableBody}
                                     setAlignData={(alignInfo) => setAlignInfo(alignInfo)}/>
                  </div>
                </div>


                {totalPage > 1 && (isTicketShowMode ? lowerTicketDataList.length > 0 : lowerTableBody.length > 0) &&
                    <div className="mt-10 px-10 lg:ml-64 xl:ml-0">
                      {/* 페이지네이션 */}
                      <Pagination
                          totalPage={totalPage}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                      />
                    </div>
                }

              </div>
            </main>
          </div>
        </div>
      </>
  )
}
export default AdminReportPage;
