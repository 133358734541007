import React, {useState} from "react";
import {UseTicketList} from "../../utils/SimpleComponentUtils";
import {MONEY_BUTTON_DATA, SHEET_QUANTITY} from "../../constants/component_data";
import {accountInfo, getLogoImage} from "../../constants/constants";
import {toast} from "react-toastify";
import {RiCameraFill, RiCloseLine, RiFileWarningFill, RiStickyNoteFill} from "react-icons/ri";
import TicketNotification from "../TicketNotification";
import SimpleTextEditModal from "../Modal/SimpleTextEditModal";
import {getFetcher} from "../../utils/fetcher";
import {DELETE_GAME_TICKET_API, UPDATE_TICKET_MEMO_API} from "../../constants/api_constants";
import ListSelectBox from "../ListSelectBox";
import neverLandUtils from "../../utils/NeverLandUtils";
import post_it_img from "../../assets/images/post_It.png";
import Decimal from "decimal.js";
import neverLandGameData from "../../utils/NeverLandGameData";
import {customToast} from "../../utils/customToast";

export function getCardStateDisplayText(state) {
  // 상태값 가져오기
  if (state.includes('expired'))
    return "만료";
  if (state === 'cart')
    return "카트";

  let stateText =
      state.includes('hold') ? '보류' :
          state.includes('order<-abort') ? '취소' :
              state.includes('request_ex_bona<-abort') ? '요청취소' :
                  state.includes('request') ? '요청' :
                      state.includes('<-abort') ? '취소' :
                          state.includes('print') ? '출력' :
                              state.includes('done') ? '완료' :
                                  state.includes('order') ? '의뢰' : '미확인';
  return stateText;
}

export class CardHandlerInstance {
  setIsSelectedTickets;

  constructor(setIsSelectedTickets) {
    this.setIsSelectedTickets = setIsSelectedTickets;
  }

  clearSelection() {
    this.setIsSelectedTickets();
  }
}

const BaseTicketCard = ({
                          gameDataList, onSelectedTickets, onRefreshTicket = () => {
  }, ticketOrderType, ticketState, cardHandler, isExport = false
                        }) => {
  const [modalData, setModalData] = useState({isOpen: false, target: null, text: ""});
  const [isSelectedTickets, setIsSelectedTickets] = useState([]);
  const [purchaseAmount, handleInputChange, handleButtonClick, resetAmount] = UseTicketList(gameDataList, {});
  const [showNotificationJson, setShowNotificationJson] = useState({isVisible: false});
  const [selectedSheetQuantity, setSelectedSheetQuantity] = useState(SHEET_QUANTITY[0]);
  const [hidePostIt, setHidePostIt] = useState([]);

  const [redrawDate, setRedrawDate] = useState(new Date());

  const cardHandlerInstance = new CardHandlerInstance(() => {
    let emptyList = [];
    if (onSelectedTickets !== null && onSelectedTickets !== undefined)
      onSelectedTickets(emptyList);
    setIsSelectedTickets(emptyList);
  });

  if (cardHandler) {
    cardHandler(cardHandlerInstance);
  }

  const getGameRound = (roundData) => {
    // 회차정보
    let round = roundData.substring(7);
    if (round.startsWith('0')) {
      return round.substring(1);
    } else {
      return round;
    }
  }

  const getBackgroundImageKey = (state, gameDataResult) => {
    // 상태값, 당낙결과에 따른 배경이미지 가져오기
    if (state.includes('expired')) {
      return 'ticketExpired';
    }
    if (gameDataResult === undefined)
      return 'ticketNormal';
    if (gameDataResult === "lose") {
      return 'ticketLose';
    }
    if (gameDataResult.includes("win")) {
      return 'ticketWin';
    }
    return 'ticketOrange';
  }

  const getTextLogoImageKey = (state, shape, isAnimate) => {
    // 상태값에 따른 텍스트 로고, 티켓 사이드 로고 가져오기
    if (shape === 'textLogo') {
      return state.includes('expired') ? 'textLogoGray' : (isAnimate ? 'textLogoBlack_animate' : 'textLogoBlack');
    }
    if (shape === 'sideLogo') {
      return state.includes('expired') ? 'ticketSideGray' : 'ticketSideOrange';
    }
  }

  const selectAllTicket = (isChecked) => {
    // 티켓 전체선택
    let tempList = [];
    if (isChecked) {
      for (let f of gameDataList) {
        if (f.state.includes('expired')) continue;
        tempList.push(f.id);
      }
      if (onSelectedTickets !== null && onSelectedTickets !== undefined)
        onSelectedTickets(tempList);
      setIsSelectedTickets([...tempList]);
      return;
    }
    if (onSelectedTickets !== null && onSelectedTickets !== undefined)
      onSelectedTickets([]);
    setIsSelectedTickets([]);
  }

  const isAllChecked = () => {
    // 전체 체크 여부
    let count = 0;

    for (let f of gameDataList) {
      if (f.state.includes('expired')) continue;
      count++;
    }
    if (count === 0)
      return false;
    return count === isSelectedTickets.length;
  }

  function isEnabledDeletionTicket() {
    if (!ticketOrderType)
      return false;
    return ticketOrderType.options.includes("enable-expired-delete");
  }

  const handleSelectTicketChange = (key, state) => {
    // 티켓 만료여부 확인
    if (state.includes('expired')) {
      if (!isEnabledDeletionTicket()) {
        toast.warn('만료된 티켓입니다.')
        return;
      } else {
        customToast.info({
          title: "만료된 티켓", message: "만료된 티켓입니다. 삭제 하시겠습니까?", button: ["삭제", "취소"], onClick: (name) => {
            if (name === '삭제') {
              getFetcher().post(DELETE_GAME_TICKET_API, {
                user: accountInfo.makeJson(),
                gamedataIds: [key]
              }).then(res => {
                const {type, message} = res.data.header;
                if (type === 'success') {
                  toast.success(message);
                  onRefreshTicket();
                } else toast.warning(message);
              }).catch(err => toast.error(err.message));
            }
          }

        });
      }
      return;
    }

    if (ticketOrderType && ticketOrderType.options.includes("single-selection")) {
      // 하나만 선택하려고 할 때 (여러개 선택 X)
      let _newTickets;
      setIsSelectedTickets((prevTickets) => {
        if (prevTickets.includes(key)) {
          _newTickets = [];
        } else {
          _newTickets = [key];
        }

        if (onSelectedTickets !== null && onSelectedTickets !== undefined)
          onSelectedTickets(_newTickets);

        return _newTickets;
      });
      return;
    }
    // 티켓 하나씩 체크할 때 (여러개 O)
    setIsSelectedTickets((prevTickets) => {
      let _newTickets;
      if (prevTickets.includes(key)) {
        _newTickets = prevTickets.filter((selectedId) => selectedId !== key);
      } else {
        _newTickets = [...prevTickets, key];
      }
      if (onSelectedTickets !== null && onSelectedTickets !== undefined)
        onSelectedTickets(_newTickets);

      return _newTickets;
    });
  }
  let _isAllChecked = isAllChecked();

  function getBadgeStateStyleText(state) {
    // 상태값 따라 배지 색상 className 반환
    if (state.includes('expired'))
      return 'bg-gray-200 text-gray-700 ring-gray-500';

    if (state === 'cart')
      return 'bg-blue-100 text-blue-600 ring-blue-500';

    if (ticketOrderType && ticketOrderType.view.includes('stateDetail')) {
      return state.includes('hold') ? 'bg-gray-300 text-gray-700 ring-1 ring-gray-500' :  //  보류
          state.includes('order<-abort') ? 'bg-red-300 text-red-700 ring-1 ring-red-500' :  // 취소
              state.includes('request_ex_bona<-abort') ? 'bg-orange-300 text-orange-700 ring-1 ring-orange-500' :
                  state.includes('<-abort') ? 'bg-red-300 text-red-700 ring-1 ring-red-500' :// 요청 취소
                      state.includes('request') ? 'bg-blue-100 text-blue-600 ring-1 ring-blue-500' :  // 요청
                          state.includes('print') ? 'bg-green-100 text-green-600 ring-1 ring-green-500' :  // 출력
                              state.includes('done') ? 'bg-green-100 text-green-600 ring-1 ring-green-500' :  // 완료
                                  'bg-white text-gray-900 ring-1 ring-gray-500' // 그외
    }
    return 'bg-white text-gray-900 ring-gray-500'
  }

  function getBorderStateStyleText(state, id) {
    // 상태값 따라 티켓 테두리 색상 className 반환
    if (state === 'cart' && isSelectedTickets.includes(id)) {
      // 카트 페이지 + 체크한 티켓 => 오렌지
      return 'ring-4 ring-orange-600';
    }
    if (ticketOrderType && ticketOrderType.view.includes('stateDetail')) {
      // 의뢰내역
      return state.includes('hold') ? 'ring-4 ring-gray-500' : // 보류
          state.includes('order<-abort') ? 'ring-4 ring-red-500' :  // 취소
              state.includes('request_ex_bona<-abort') ? 'ring-4 ring-amber-500' :  // 요청 취소
                  state.includes('<-abort') ? 'ring-4 ring-red-500' :  // 취소
                      state.includes('request') ? 'ring-4 ring-blue-600' :  // 요청
                          state.includes('print') ? 'ring-4 ring-green-500' :  // 출력
                              state.includes('done') ? 'ring-4 ring-indigo-500' :  // 완료
                                  'ring-1 ring-gray-400' // 그외
    }
    return 'ring-gray-400';
  }

  function isCardStateVisible(state) {
    if (ticketOrderType && ticketOrderType.view.includes('stateDetail')) {
      return true;
    }
    return state.includes('expired');
  }

  function viewPopUpEditor(target, memo) {
    // 메모수정 모달 띄우기
    let thisMemo = memo;
    let memoHeader = "";

    if (thisMemo.includes(')') && thisMemo.includes('방문예정일시 :')) {
      let temp = memo.split(')');
      thisMemo = temp[1].trim();
      memoHeader = temp[0] + ")";
    }
    setModalData({isOpen: true, target: target, text: thisMemo, header: memoHeader});
  }

  function onEditMemo(newModalData) {
    // 메모 수정
    if (!newModalData.target || !newModalData.target.id) {
      setModalData({isOpen: false, target: null, text: ""});
      return;
    }
    if (modalData.text === null || newModalData.text === modalData.text) {
      setModalData({isOpen: false, target: null, text: ""});
      return;
    }
    let memo = newModalData.text;

    if (newModalData.header) {
      memo = newModalData.header + memo;
    }
    getFetcher().post(UPDATE_TICKET_MEMO_API, {
      user: accountInfo.makeJson(),
      myGameDataId: newModalData.target.id,
      memo: memo,
      target: newModalData.target.type
    }).then(res => {
      const {type, message} = res.data.header;
      if (type === 'success') {
        toast.success(message);
        onRefreshTicket();
      } else toast.warning(message);
    }).catch(err => toast.error(err.message));
    setModalData({isOpen: false, target: null, text: ""});
  }

  function sortCardSelectedGame(selectGame) {
    // <-- 경기번호 순서대로 정렬 -->
    selectGame.sort((t1, t2) => {
      return t1.allotId.localeCompare(t2.allotId);
    })
    return selectGame;
  }

  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  const getPlayDateFormatText = (gameplayDate) => {
    let date = new Date(gameplayDate);
    return date.toLocaleString('ko-KR', options).replace("일 ", "일 (").replace("요일", ')')
  }

  function _getGameRate(length, amount, rate) {
    // 소수점 계산 오류 방지
    if (rate === null) return 0;
    if (amount === null) return 0;
    const rateDecimal = new Decimal(rate);
    let _rateText = rateDecimal.times(amount).toString();
    return Number(_rateText);
  }

  if (isExport) {
    let card = gameDataList[0];
    let amount = card.amount;
    // <------------------------------------- 캡처 영역 ------------------------------------->
    return (<div id={'ticketCapture_' + card.id} className="w-ticket border-2 p-1">
      <p className="font-semibold text-sm text-right mt-1"> {card.selectGame.length}경기 선택 </p>
      <div>
        <div className="text-xs font-semibold w-full mt-3 text-center">
          <div className="border-t-2 border-dashed border-black mb-1"></div>
          <div className="flex">
            <div className="w-14">경기</div>
            <div className="w-5/12 pr-3">홈팀</div>
            <div>:</div>
            <div className="w-3/12">원정팀</div>
            <div className="w-2/12">예상</div>
            <div className="w-2/12">배당률</div>
          </div>
          <div className="border-t-2 border-dashed border-black mt-1"></div>
        </div>

        {(
            <div key={card.id} className="font-bold">
              {sortCardSelectedGame(card.selectGame)?.map((game) => {

                let _notiPopVisable = showNotificationJson.isVisible && showNotificationJson.data === game;
                return (
                    <div key={game.allotId}
                         className={neverLandUtils.classNames(
                             game.allotResult === null || game.allotResult === '-' ? ''
                                 : game.allotResult === 'o' ? 'bg-green-100'
                                     : game.allotResult === 'reject' ? 'bg-amber-200'
                                         : 'bg-red-200', 'text-xs w-full flex text-center mt-1 items-center justify-center')}
                    >
                      <div className="w-11 flex flex-row justify-between mr-3">
                        <div className="w-5">
                          {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) &&
                              <p>
                                {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.replace("⒮", "S").substring(0, 1) : ""}
                              </p>
                          }
                        </div>
                        <p className="w-9">
                          {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.substring(1) : game.gameNumber}
                        </p>
                      </div>

                      <div className="w-[6.7rem] pl-3 text-left whitespace-nowrap">
                        <p>{game.homeTeam}</p>
                      </div>
                      <div className="">:</div>
                      <div
                          className="w-[3.5rem] ml-3 text-left whitespace-nowrap">{game.awayTeam}</div>
                      <div
                          className="w-2/12 mr-2"> {neverLandGameData.getPrediction(game.gameNumber, game.prediction, game.category)} </div>

                      <div
                          className="w-1/12 flex flex-row items-center cursor-pointer justify-center"
                          onClick={() => {
                            if (!_notiPopVisable) {
                              setShowNotificationJson({
                                isVisible: true,
                                data: game
                              })
                            }
                          }}
                      >
                        {
                          game.rateNew > 0 ?
                              <div
                                  className={game.rateNew.toFixed(2) > game.rate.toFixed(2) ? " text-blue-600" : "text-red-600"}>
                                {game.allotResult === 'reject' ? 1 : game.rate.toFixed(2)}
                              </div>
                              :
                              <div> {game.allotResult === 'reject' ? 1 : game.rate.toFixed(2)} </div>
                        }

                        {game.isChanged &&
                            <div className="relative ignore-export">
                              {_notiPopVisable &&
                                  <div className="absolute right-2 z-50 min-w-96">
                                    <TicketNotification showNotificationJson={showNotificationJson}
                                                        onClose={() => {
                                                          setShowNotificationJson({
                                                            isVisible: false,
                                                            data: game
                                                          });
                                                        }}
                                    />
                                  </div>
                              }

                              <RiFileWarningFill className="text-red-600 h-3 w-auto ml-1"/>
                            </div>
                        }
                      </div>
                    </div>
                );
              })}
            </div>
        )}
        <div className="border-t-2 border-dashed border-black mt-1"></div>
      </div>

      <div className="flex flex-row justify-between items-center mt-3 text-sm font-semibold">
        <p className="w-1/2 text-right">예상 적중배당률 :</p>
        <div className="flex flex-row">
          <p className="mr-1">{card.totalRate}</p>
          <p> 배</p>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center mt-2 text-sm font-semibold">
        <p className="w-1/2 text-right">개별투표금액 :</p>
        <div className="flex flex-row items-center">
          {ticketState ? ((ticketState.includes('!cart') || (ticketOrderType && ticketOrderType.options.includes('fixed'))
                  || card.state === undefined || card.state.includes('expired')) ?
                  <p className="mr-1">{amount?.toLocaleString() || 0}</p>
                  :
                  <div
                      className="bg-white flex flex-row rounded-md border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-2 ml-4 mr-2">
                    <input
                        disabled={(ticketOrderType && ticketOrderType.options.includes('fixed')) || card.state === undefined || card.state.includes('expired')}
                        value={amount.toLocaleString()}
                        onChange={(e) => handleInputChange(e, card.id)}
                        onFocus={(e) => {
                          // 커서 제일 끝으로 보내기
                          setTimeout(() => {
                            const length = e.target.value.length;
                            e.target.setSelectionRange(length, length);
                          }, 0);
                        }}
                        className="text-left w-20 font-semibold text-gray-900 sm:text-sm sm:leading-6 focus:ring-0 focus:ring-white"
                    />
                    <button type="reset">
                      <RiCloseLine className="h-4 w-auto px-1"
                                   onClick={() => resetAmount(card.id)}/>
                    </button>
                  </div>
          ) : <p className="mr-1">{amount?.toLocaleString() || 0}</p>
          }
          <p>원</p>
        </div>
      </div>

      {ticketOrderType ? (!(ticketOrderType.options.includes('fixed')) && card.state !== undefined && !card.state.includes('expired')) && (
          <div className="flex flex-row justify-between mt-3">
            {MONEY_BUTTON_DATA.map((data) => (
                <button
                    key={card.id + data.value}
                    onClick={() => handleButtonClick(card.id, data.value)}
                    value={data.value}
                    type="button"
                    className="whitespace-nowrap px-7 py-1 text-sm font-semibold text-white shadow-sm rounded-md bg-orange-600 pcSizeHover:hover:bg-orange-500"
                >
                  {data.name}
                </button>
            ))}
          </div>
      ) : (<></>)}

      <div className="flex flex-row justify-between items-center mt-2 text-sm font-semibold">
        <p className="w-1/2 text-right">예상 적중금 :</p>
        <div className="flex flex-row">
          <p className="text-black mr-1">
            {(_getGameRate(card.selectGame.length, amount, card.totalRate || 0)).toLocaleString() || 0}
          </p>
          <p> 원</p>
        </div>
      </div>
    </div>);
  }

  // <------------------------------------- 티켓 카드 영역 ------------------------------------->
  return (
      <div className="flex flex-col">
        {modalData.isOpen && <SimpleTextEditModal title={"메모 수정"} modalData={modalData} setModalData={onEditMemo}/>}
        {gameDataList.length >= 1 && onSelectedTickets && !(ticketOrderType && ticketOrderType.options.includes("single-selection")) &&
            <div className="flex h-6 items-center text-center font-semibold">
              <input
                  onClick={(e) => selectAllTicket(e.target.checked)}
                  type="checkbox"
                  checked={_isAllChecked}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
              />
              <span>전체 선택</span>
            </div>
        }

        {/* <------------------------------------- 티켓 카드 ------------------------------------->*/}
        <div
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 xl-only:grid-cols-3 2xl-only:grid-cols-4">
          {gameDataList?.map((card) => {
            if (card.selectGame.length === 0)
                // return (<></>);
              return (
                  <div key={card.id}>
                    데이터 없음
                    {card.id + "/" + card.registDate}
                  </div>
              )
            let amount = purchaseAmount[card.id] || 0;

            card.newAmount = amount;

            if (ticketOrderType && ticketOrderType.options.includes("copy-selectable") && card.copyNumber === undefined) {
              card.copyNumber = 1;
            }

            let currentState = card.state;

            if (ticketOrderType && ticketOrderType.stateFilter)
              currentState = ticketOrderType.stateFilter(currentState);

            // 삭제된 계정이 의뢰한 티켓은 티켓대신 아래 div 띄우기
            if (card.displayTag?.includes("#hidden")) {
              return (
                  <div key={card.id}
                       className="w-full h-[13rem] border-2 border-gray-500 bg-gray-200 mt-5 p-2 justify-between flex flex-col shadow-md"
                       onClick={() => {
                         card.displayTag = "none";
                         setRedrawDate(new Date());
                       }}>
                    <div>
                      {isCardStateVisible(currentState) &&
                          <span
                              className={`inline-flex items-center rounded-full px-2 py-1.5 text-xs font-semibold mb-1 ring-1 shadow-md ${getBadgeStateStyleText(currentState)}`}
                          >
                                {currentState.includes('cart') ? getCardStateDisplayText(currentState) : (getCardStateDisplayText(currentState) + " : " + card.requestNumber)}
                             </span>
                      }

                    </div>
                    <div className="py-3">
                      <p className="bg-gray-600 text-white px-2">※ 삭제된 계정이 의뢰한 건입니다.</p>
                      <p className="text-xs mt-0.5">- 클릭시 상세 내용 확인 가능</p>
                    </div>
                    {ticketOrderType && ticketOrderType.view.includes('register') && ticketOrderType.options.includes("memo-user-editable") ?
                        <div>
                          <p>className="text-sm font-semibold mt-4 cursor-pointer
                            text-blue-600"> {card.registerMemo}</p>
                        </div>
                        :
                        <div>
                          {!(ticketOrderType && ticketOrderType.view.includes("memo-user-invisible")) &&
                              <>
                                <p className="text-xs font-semibold mt-4"> {card.registerName.replace(" | 연락처 미등록", "").replace("| -", "").trim()}</p>
                                <p className="text-sm font-semibold mt-4 text-blue-600">{card.registerMemo}</p>
                              </>
                          }
                        </div>
                    }
                  </div>
              )
            }

            // 경기가 진행중일때 로고 깜빡이는 이미지로
            let isAnimate = card.gameDataResult === "notyet";
            if (isAnimate) {
              let now = neverLandUtils.utils.getDisplayTimeText2(new Date());
              if (now.localeCompare(card.gameplayDate) < 0) {
                isAnimate = false;
              }
            }
            return (
                <div key={card.id}
                     className={`px-1 mt-4 bg-white shadow w-ticket flex flex-row ring-1 ${getBorderStateStyleText(currentState, card.id)}`}
                >
                  <div className="py-5 px-2 w-full relative bg-auto"
                       style={{
                         backgroundImage: `url(${getLogoImage(getBackgroundImageKey(currentState, card.gameDataResult))})`,
                         backgroundPosition: card.selectGame.length >= 6 ? "0 50px" : "center", // 경기가 많아지면 도장위치
                         backgroundRepeat: "no-repeat",
                       }}>
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row cursor-pointer"
                             onClick={() => handleSelectTicketChange(card.id, card.state)}>
                          {onSelectedTickets && <input
                              checked={isSelectedTickets.includes(card.id)}
                              type="checkbox"
                              className="mr-1 h-4 w-4 rounded border-gray-300 text-indigo-600"
                          />}
                          <img src={getLogoImage(getTextLogoImageKey(currentState, 'textLogo', isAnimate))}
                               alt="MobydickLogo"
                               className="object-center object-contain w-24 mx-1"/>
                        </div>

                        <div className="flex flex-row">
                          {/*{ticketOrderType && ticketOrderType.options.includes("copy-selectable") &&*/}
                          {/*    <ListSelectBox listData={SHEET_QUANTITY}*/}
                          {/*                   onChange={(e) => {*/}
                          {/*                     card.copyNumber = e.name.replace("매", "").trim();*/}
                          {/*                     setSelectedSheetQuantity(e)*/}
                          {/*                   }}/>*/}
                          {/*}*/}

                          {isCardStateVisible(currentState) &&
                              <span
                                  className={`inline-flex items-center rounded-full px-2 py-1.5 text-xs font-semibold mb-1 ring-1 ${getBadgeStateStyleText(currentState)}`}
                              >
                                {currentState.includes('cart') ? getCardStateDisplayText(currentState) : (getCardStateDisplayText(currentState) + " : " + card.requestNumber)}
                             </span>
                          }
                        </div>
                      </div>
                      <div className="flex flex-row mt-2 justify-between items-center text-xl font-bold">
                        <p> 프로토(승부식)</p>
                        <p>{getGameRound(card.gameRound)}회차 </p>
                      </div>
                    </div>

                    <div className="text-xs mt-1">
                      <div className="flex justify-between">
                        <p className="w-1/2">요청시간 : {card.registDate.substring(5, 16)}</p>
                        <p className="text-right">{card.chargerName?.replace("| -", "")}</p>
                      </div>
                      <div className="flex justify-between">
                        {card.progressDate !== "-" &&
                            <p className="w-1/2">처리시간 : {card.progressDate.substring(5, 16)}</p>}
                        {card.reservDate && card.reservDate.length > 19 &&
                            <p className="text-right">방문시간 : {card.reservDate.substring(5, 19)}</p>}
                      </div>
                      <p className="mt-1"></p>
                    </div>

                    {/*포스트잇 (취소사유 띄우기) */}
                    {card.extraDescription && !hidePostIt.includes(card.id) &&
                        <div
                            className={neverLandUtils.classNames(card.extraTypeDescription = 'cancel' ? "" : "", "absolute z-10 w-[20rem] h-72 flex flex-col")}
                            style={{
                              backgroundImage: `url(${post_it_img})`,
                              backgroundSize: "100% auto",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center"
                            }}
                        >
                          <div className="flex flex-row justify-end mx-7 mt-4">
                            <RiCloseLine className="h-6 w-auto"
                                         onClick={() => setHidePostIt((prevList) => [...prevList, card.id])}/>
                          </div>
                          <p className="items-center text-center mx-5 my-5"> {card.extraDescription} </p>
                        </div>
                    }

                    <div className="flex flex-row justify-between items-center">
                      {(accountInfo.isStore() && ticketState && ticketState.includes('!cart')) ?
                          <div title={"카메라를 클릭하면 티켓 이미지가 복사됩니다."}>
                            <button
                                onClick={() => neverLandUtils.tools.convertHtmlToPngAndCopyToClipboard('ticketCapture_' + card.id, false)}>
                              <RiCameraFill className="h-6 w-auto text-gray-900"/>
                            </button>
                          </div>
                          :
                          <div></div>
                      }
                      <p className="text-sm text-right font-bold">경기시작 : {getPlayDateFormatText(card.gameplayDate)}</p>
                    </div>

                    {/* 캡처이미지 구역 */}
                    <div id={'ticketCapture_' + card.id}>
                      <p className="font-semibold text-sm text-right mt-1"> {card.selectGame.length}경기 선택 </p>
                      <div>
                        <div className="text-xs font-semibold w-full mt-3 text-center">
                          <div className="border-t-2 border-dashed border-black mb-1"></div>
                          <div className="flex">
                            <div className="w-14">경기</div>
                            <div className="w-5/12 pr-3">홈팀</div>
                            <div>:</div>
                            <div className="w-3/12">원정팀</div>
                            <div className="w-2/12">예상</div>
                            <div className="w-2/12">배당률</div>
                          </div>
                          <div className="border-t-2 border-dashed border-black mt-1"></div>
                        </div>

                        {(
                            <div className="font-bold">
                              <div key={card.id}>
                                {sortCardSelectedGame(card.selectGame)?.map((game) => {

                                  let _notiPopVisable = showNotificationJson.isVisible && showNotificationJson.data === game;
                                  return (
                                      <div key={game.allotId}
                                           className={neverLandUtils.classNames(
                                               game.allotResult === null || game.allotResult === '-' ? ''
                                                   : game.allotResult === 'o' ? 'bg-green-100'
                                                       : game.allotResult === 'reject' ? 'bg-amber-200'
                                                           : 'bg-red-200', 'text-xs w-full flex text-center mt-1 items-center')}
                                      >
                                        <div className="w-11 flex flex-row justify-between">
                                          <div className="w-3">
                                            {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) &&
                                                <p>
                                                  {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.replace("⒮", "S").substring(0, 1) : ""}
                                                </p>
                                            }
                                          </div>
                                          <p className={neverLandUtils.classNames(game.gameNumber.includes('*') ? "" : "ml-2", "w-8")}>
                                            {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.substring(1) : game.gameNumber}
                                          </p>
                                        </div>

                                        <div className="w-[6.7rem] pl-4 text-left whitespace-nowrap">
                                          <p>{game.homeTeam}</p>
                                        </div>
                                        <div className="">:</div>
                                        <div
                                            className="w-[3.5rem] pl-2 text-left whitespace-nowrap">{game.awayTeam}</div>
                                        <div
                                            className="w-2/12 pl-1"> {neverLandGameData.getPrediction(game.gameNumber, game.prediction, game.category)} </div>

                                        <div
                                            className="w-1/12 ml-1 flex flex-row items-center cursor-pointer justify-center"
                                            onClick={() => {
                                              if (!_notiPopVisable) {
                                                setShowNotificationJson({
                                                  isVisible: true,
                                                  data: game
                                                })
                                              }
                                            }}
                                        >
                                          {
                                            game.rateNew > 0 ?
                                                <div
                                                    className={game.rateNew.toFixed(2) > game.rate.toFixed(2) ? " text-blue-600" : "text-red-600"}>
                                                  {game.allotResult === 'reject' ? 1 : game.rate.toFixed(2)}
                                                </div>
                                                :
                                                <div> {game.allotResult === 'reject' ? 1 : game.rate.toFixed(2)} </div>
                                          }

                                          {game.isChanged &&
                                              <div className="relative ignore-export">
                                                {_notiPopVisable &&
                                                    <div className="absolute right-2 z-50 min-w-96">
                                                      <TicketNotification showNotificationJson={showNotificationJson}
                                                                          onClose={() => {
                                                                            setShowNotificationJson({
                                                                              isVisible: false,
                                                                              data: game
                                                                            });
                                                                          }}
                                                      />
                                                    </div>
                                                }

                                                <RiFileWarningFill className="text-red-600 h-3 w-auto ml-1"/>
                                              </div>
                                          }
                                        </div>
                                      </div>
                                  );
                                })}
                              </div>
                            </div>
                        )}
                        <div className="border-t-2 border-dashed border-black mt-1"></div>
                      </div>

                      <div className="flex flex-row justify-between items-center mt-3 text-sm font-semibold">
                        <p className="w-1/2 text-right">예상 적중배당률 :</p>
                        <div className="flex flex-row">
                          <p className="mr-1">{card.totalRate}</p>
                          <p> 배</p>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between items-center mt-2 text-sm font-semibold">
                        <p className="w-1/2 text-right">개별투표금액 :</p>
                        <div className="flex flex-row items-center">
                          {ticketState ? ((ticketState.includes('!cart') || (ticketOrderType && ticketOrderType.options.includes('fixed'))
                                  || card.state === undefined || card.state.includes('expired')) ?
                                  <p className="mr-1">{amount?.toLocaleString() || 0}</p>
                                  :
                                  <div
                                      className="bg-white flex flex-row rounded-md border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-2 ml-4 mr-2">
                                    <input
                                        disabled={(ticketOrderType && ticketOrderType.options.includes('fixed')) || card.state === undefined || card.state.includes('expired')}
                                        value={amount.toLocaleString()}
                                        onChange={(e) => handleInputChange(e, card.id)}
                                        onFocus={(e) => {
                                          // 커서 제일 끝으로 보내기
                                          setTimeout(() => {
                                            const length = e.target.value.length;
                                            e.target.setSelectionRange(length, length);
                                          }, 0);
                                        }}
                                        className="text-left w-20 font-semibold text-gray-900 sm:text-sm sm:leading-6 focus:ring-0 focus:ring-white"
                                    />
                                    <button type="reset">
                                      <RiCloseLine className="h-4 w-auto px-1"
                                                   onClick={() => resetAmount(card.id)}/>
                                    </button>
                                  </div>
                          ) : <p className="mr-1">{amount?.toLocaleString() || 0}</p>
                          }
                          <p>원</p>
                        </div>
                      </div>

                      {ticketOrderType ? (!(ticketOrderType.options.includes('fixed')) && card.state !== undefined && !card.state.includes('expired')) && (
                          <div className="flex flex-row justify-between mt-3">
                            {MONEY_BUTTON_DATA.map((data) => (
                                <button
                                    key={card.id + data.value}
                                    onClick={() => handleButtonClick(card.id, data.value)}
                                    value={data.value}
                                    type="button"
                                    className="whitespace-nowrap px-7 py-1 text-sm font-semibold text-white shadow-sm rounded-md bg-orange-600 pcSizeHover:hover:bg-orange-500"
                                >
                                  {data.name}
                                </button>
                            ))}
                          </div>
                      ) : (<></>)}

                      <div className="flex flex-row justify-between items-center mt-2 text-sm font-semibold">
                        <p className="w-1/2 text-right">예상 적중금 :</p>
                        <div className="flex flex-row">
                          <p className="text-black mr-1">
                            {(_getGameRate(card.selectGame.length, amount, card.totalRate || 0)).toLocaleString() || 0}
                          </p>
                          <p> 원</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-center mt-2 text-lg font-bold">
                      <p className="mr-2">총 투표금액</p>
                      <p className="mr-1">{amount?.toLocaleString() || 0}</p>
                      <p>원</p>
                    </div>

                    {ticketOrderType && ticketOrderType.view.includes('register') && ticketOrderType.options.includes("memo-user-editable") ?
                        <div>
                          <p onClick={() => viewPopUpEditor({type: 'user', id: card.id}, card.registerMemo)}
                             className="text-sm font-semibold mt-4 cursor-pointer text-blue-600"> {card.registerMemo}</p>
                        </div>
                        :
                        <div>
                          {!(ticketOrderType && ticketOrderType.view.includes("memo-user-invisible")) &&
                              <>
                                <p className="text-xs font-semibold mt-4"> {card.registerName.replace(" | 연락처 미등록", "").replace("| -", "").trim()}</p>
                                <p className="text-sm font-semibold mt-4 text-blue-600">{card.registerMemo}</p>
                              </>
                          }
                        </div>
                    }

                    {ticketOrderType ? (ticketOrderType.view.includes('charger') || ticketOrderType.view.includes('charger-memo-only')) &&
                        <div>
                          {!(ticketOrderType && ticketOrderType.view.includes('charger-memo-only')) &&
                              <p className="text-xs font-semibold mt-4"> {card.chargerName?.replace("| -", "")}</p>}

                          {!ticketOrderType.view.includes('memo-charger-invisible') && ticketOrderType && ticketOrderType.options.includes("memo-store-editable") &&
                              <div className="items-center mt-4 flex flex-row"
                                   onClick={() => viewPopUpEditor({type: 'store', id: card.id}, card.chargerMemo)}>
                                {card.chargerMemo === '-' ? "" :
                                    <p className="text-sm font-semibold cursor-pointer text-blue-600"> {card.chargerMemo} </p>
                                }
                                <RiStickyNoteFill className="w-6 h-6 cursor-pointer text-amber-400 ml-2"/>
                              </div>}

                          {!ticketOrderType.view.includes('memo-charger-invisible') && !(ticketOrderType && ticketOrderType.options.includes("memo-store-editable")) &&
                              <p className="text-sm font-semibold mt-4 text-blue-600">{card.chargerMemo}</p>}
                        </div>
                        : <p className="text-xs font-semibold mt-4">{card.chargerName} : {card.chargerMemo}</p>
                    }
                  </div>

                  <div className="w-4 ml-3"
                       style={{backgroundImage: `url(${getLogoImage(getTextLogoImageKey(card.state, 'sideLogo'))})`}}>
                  </div>
                </div>
            )
                ;
          })}
        </div>
      </div>
  )
}
export default BaseTicketCard;
