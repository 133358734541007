import {getFetcher} from "./fetcher";
import {
  RE_LOGIN_ACCOUNT_API
} from "../constants/api_constants";
import {accountInfo} from "../constants/constants";
import {toast} from "react-toastify";
import {MAIN_PAGE} from "../constants/page_constants";
import neverLandUtils from "./NeverLandUtils";

export const updateMyInfo = (navigate) => {
  // 로그인 갱신
  getFetcher().post(RE_LOGIN_ACCOUNT_API, {
    user: accountInfo.makeJson()
  }).then(res => {
    const {type, message} = res.data.header;
    //회원삭제 시 리로그인 구현
    if (neverLandUtils.security.checkLogout(res)) {
      return;
    }
    if (type === 'success') {
      let userInfo = res.data.result.user;
      accountInfo.login(userInfo);
      if (navigate) {
        toast.info('[알림] 정보 변경이 적용되었습니다.');
        accountInfo.saveLastNavigation(MAIN_PAGE);
        navigate(MAIN_PAGE);
      } else {
        window.location.reload();
      }
    } else {
      toast.error(message);
    }
  }).catch(err => toast.error(err.message));
}


