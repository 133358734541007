import neverLandGameData from "../../utils/NeverLandGameData";
import {useEffect, useState} from "react";
import {RiCloseLine, RiPushpinFill} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";

const SelectedGameTable = () => {
  const [index, setIndex] = useState(0);
  // const [isFix, setIsFix] = useState([]);

  const updateUI = () => {
    setIndex(prevState => prevState + 1);
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, [])

  if (neverLandGameData.selectedAllotData.length === 0)
    return null;

  // const handleFixGame = (id, selectionKey) => {
  //   const _id = id + "|" + selectionKey;
  //   // 경기 고정 체크 여부 -->
  //   setIsFix((prevGames) => {
  //     if (prevGames.includes(_id)) {
  //       return prevGames.filter((selectedId) => selectedId !== _id);
  //     } else {
  //       return [...prevGames, _id];
  //     }
  //   })
  // }
  // 경기 추가하고 빠질때 fix 아이콘 싱크맞추기
  // let _preFix = isFix;
  // let newFix = [];
  // let isChanged = false;
  //
  // for (let f of _preFix) {
  //   if (!neverLandGameData.selectedAllotData.find(item => (item.allotId + "|" + item.prediction) === f)) {
  //     isChanged = true;
  //   } else {
  //     newFix.push(f);
  //   }
  // }
  // if (isChanged) {
  //   setIsFix(newFix);
  // }

  const handleCheckboxChange = (id, selectionKey) => {
    // 체크박스 onChange 핸들링
    neverLandGameData.toggleFixedAllotId(id, selectionKey);
    // handleFixGame(id, selectionKey);
  };

  return (
      <div className="flex justify-center mt-5 mb-5 rounded-lg">
        <table className="divide-y divide-gray-300 ring-1 ring-black ring-opacity-5">
          <thead className="bg-stone-900 text-white text-sm leading-6">
          <tr>
            <th className="whitespace-nowrap py-2 px-3 font-semibold">
              고정
            </th>
            <th className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
              경기
            </th>
            <th className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
              홈팀
            </th>
            <th className="whitespace-nowrap hidden xl:table-cell py-2  px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
              원정팀
            </th>
            <th className="whitespace-nowrap py-2 px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
              예상
            </th>
            <th className="whitespace-nowrap py-2 px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
              배당률
            </th>
            <th className="whitespace-nowrap py-2 px-2 xl-only:px-5 2xl-only:px-8 font-semibold">
            </th>
          </tr>
          </thead>

          <tbody className="divide-y divide-gray-300 text-sm text-gray-900 bg-white">
          {neverLandGameData.selectedAllotData?.map((game) => (
              <tr key={game.allotId + "|" + game.prediction}>
                <td className="py-2 px-5">
                  <div className="flex h-6 items-center">
                    {neverLandGameData.isFixedAllotData(game.allotId, game.prediction)
                      //isFix.includes(game.allotId + "|" + game.prediction)
                        ?
                        <RiPushpinFill
                            onClick={() => handleCheckboxChange(game.allotId, game.prediction)}
                            className="h-4 w-4 text-red-600"
                        />
                        : <input
                            type="checkbox"
                            checked={false}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={() => handleCheckboxChange(game.allotId, game.prediction)}
                        />
                    }
                  </div>
                </td>
                <td className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8">
                  {game.gameNumber.replace("⒮", "S")}
                </td>
                <td className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8">
                  {game.homeTeam}
                </td>
                <td className="whitespace-nowrap hidden xl:table-cell py-2  px-2 xl-only:px-5 2xl-only:px-8">
                  {game.awayTeam}
                </td>
                <td className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8 text-center">
                  {neverLandGameData.getPrediction(game.gameNumber, game.prediction, game.category)}
                </td>
                <td className="whitespace-nowrap py-2  px-2 xl-only:px-5 2xl-only:px-8">
                  {game.rate}
                </td>
                <td className="py-2 px-2 xl-only:px-5 2xl-only:px-8 sm:table-cell">
                  <button className="inline-block"
                          onClick={() => neverLandGameData.toggleSelectItem(game.allotId, game.prediction)}
                  >
                    <RiCloseLine className=" h-4 w-4 text-black"/>
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
  )
}
export default SelectedGameTable;