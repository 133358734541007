import {getFetcher} from "./fetcher";
import {LIST_OF_GAME_DATA_API} from "../constants/api_constants";
import {accountInfo} from "../constants/constants";


const selection_rules = {
  use_limit_selection: false, // 경기선택수 제한 여부
  limit_selection_count: 10, // 경기선택수 제한 카운트

  use_limit_folder_max: false, // 폴더수 제한 여부
  limit_folder_max_number: 10, // 폴더수 제한 수

  use_limit_game_card_max: true, // 게임카드 제한 여부
  limit_game_card_max_number: 10, // 게임카드 제한 수
};

function _isFilteredGameType(currentFilterRules, game) {
  for (let allot of game.data) {
    if (!neverLandGameData._isFilteredGameAllotData(currentFilterRules, allot.extraText)) {
      return false;
    }
  }
  return true;
}

function _isFilteredGameCategory(currentFilterRules, category) {
  if (category === "BK") {
    category = "농구";
  }
  if (category === "BS") {
    category = "야구";
  }
  if (category === "SC") {
    category = "축구";
  }
  if (category === "VL") {
    category = "배구";
  }
  return !currentFilterRules.includes(category);
}

function makeFilteredGameData(gameData, currentFilterRule) {
  // 필터 테이블 체크값에 따라 데이터 필터링하기
  let filteredgamedata = [];

  for (let _data of gameData) {
    if (!currentFilterRule['status'].includes("전체")) {
      if (!currentFilterRule['status'].includes(_data.state)) {
        continue;
      }
    }
    if (!currentFilterRule['type'].includes("전체")) {
      if (_isFilteredGameType(currentFilterRule['type'], _data))
        continue;
      // if (!currentFilterRule['type'].includes(_data.type)) {
      //   continue;
      // }
    }
    if (!currentFilterRule['category'].includes("전체")) {
      if (_isFilteredGameCategory(currentFilterRule['category'], _data.category)) {
        continue;
      }
    }
    if (!currentFilterRule['league'].includes("전체")) {
      if (!currentFilterRule['league'].includes(_data.leagueName)) {
        continue;
      }
    }
    let closingDateString = _data.closingDate;

    if (!currentFilterRule['closingDate'].includes("전체")) {
      closingDateString = closingDateString.substring(0, 10);

      //closingDateString형태 바꺼주기
      //그전에 closingDate 을 유동적으로 세팅할 필요가 있음
      if (!currentFilterRule['closingDate'].includes(closingDateString)) {
        continue;
      }
    }

    filteredgamedata.push(_data);
  }
  return filteredgamedata;
}

function sortTicketData(_ticketData) {
  // <-- 경기번호 순서대로 정렬 -->
  for (let ticket of _ticketData) {
    ticket.selectGame.sort((t1, t2) => {
      return t1.allotId.localeCompare(t2.allotId);
    })
  }
}

function isContainsSelectionTicket(selectedItems, newItem) {
  for (let str of selectedItems) {
    if (newItem.startsWith(str.substring(0, str.split('_', 4).join('_').length))) {
      // console.log('isContainsSelectionTicket->'+newItem+":ok",selectedItems);
      return true;
    }
  }
  // console.log('isContainsSelectionTicket->'+newItem+":no",selectedItems);
  return false;
}

function getGameCardCount(_selectedItems, newItem) {
  // 선택한 경기가 있는 게임 카드 수
  let temp = newItem.substring(0, newItem.length - 7);
  let map = [temp];

  for (let i of _selectedItems) {
    temp = i.substring(0, i.length - 7);
    if (map.includes(temp)) continue;
    map.push(temp);
  }
  let count = map.length;
  map = null;
  return count;
}

function multiplyRate(r1, r2) {
  r1 *= 1000;
  r2 *= 1000;
  return (r1 * r2) / 1000000;
}

class NeverLandGameData {
  filterOptions = null;
  currentFilterRule = null;

  // 화면 중앙 게임 선택영역
  _selectedItems = [];
  gameData = [];
  filteredData = [];
  // 화면 오른쪽 선택한 게임 출력지 영역
  ticketData = [];

  //고정된 선택데이터
  fixedSelectedAllotData = [];
  //전체 선택데이터
  selectedAllotData = [];

  isFilterChanged = false;

  gameRound = -1;


  _folderList = null;

  updateNewFolderNumber() {
    this._folderList = null;
    this.folderList();
  }

  reloadGameData() {
    lastRequestDate = null;
    informationTime = null;
    reloadGameDataFromWebServer(2);
  }

  folderList() {
    if (this._folderList === null) {
      let newList = [];
      let tempList = [];
      let temp;
      let countOfNotSingle = 0;
      for (let i of this.selectedAllotData) {
        temp = i.allotId;
        temp = temp.substring(0, temp.lastIndexOf("_"));
        if (tempList.includes(temp)) continue;
        if (!i.isSingle) countOfNotSingle++;
        tempList.push(temp);
      }
      let count = tempList.length;
      count = Math.min(10, count);

      for (let index = 0; index < count; index++) {
        if (index === 0 && countOfNotSingle === count) continue;
        newList.push(index + 1);
      }
      newList.sort((a1, a2) => a2 - a1);

      this._folderList = newList;

      if (this._folderList !== null && this._folderList.length > 0) {
        this._currentFolderNumber = this._folderList[0];
      }
    }
    return this._folderList;
  }

  getPrediction = (gameNumber, prediction, category) => {
    let _prediction = prediction;
    // <-- 언더/오버 일때 승,패 대신 언더/오버 -->
    if (gameNumber.includes('U')) {
      if (prediction === '승') _prediction = '언더';
      if (prediction === '패') _prediction = '오버';
    }

    // <-- 홀/짝 일때 승,패 대신 홀/짝 -->
    if (gameNumber.includes('⒮')) {
      if (prediction === '승') _prediction = '홀';
      if (prediction === '패') _prediction = '짝';
    }

    // <-- 전반일때 앞에 (전) 붙이기 -->
    if (category === "type01") return `(전)${_prediction}`;

    return _prediction;
  }


  formatDate(dateString) {
    // 날짜 표현 바꾸기
    const date = new Date(dateString);
    const options = {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      weekday: 'short',
      hour12: false,
    };
    return date.toLocaleDateString('ko-KR', options);
  }

  isSelected(id, selectionKey) {
    // 경기 버튼 선택하면 키값, 넘기기
    if (this._selectedItems.length === 0)
      return false;
    // if(this._selectedItems.length>0)
    //   return false;
    return this._selectedItems.includes(id + "|" + selectionKey);
  }

  toggleSelectItem(id, selectionKey) {
    // 경기 버튼 선택하면 배열에 추가하기
    if (selectionKey === "승" || selectionKey === "w")
      selectionKey = "w";
    else if (selectionKey === "패" || selectionKey === "l")
      selectionKey = "l";
    else
      selectionKey = "d";
    const newItem = id + "|" + selectionKey;

    if (this._selectedItems === null) {
      this._selectedItems = [];
      this._selectedItems.push(newItem);
    } else if (this._selectedItems.length === 0) {
      this._selectedItems.push(newItem);
    } else if (!this._selectedItems.includes(newItem)) {
      // 경기선택수 제한 (현재는 제한없음)
      if (selection_rules.use_limit_selection)
        if (this._selectedItems.length === selection_rules.limit_selection_count)
          return false;

      // 폴더 10개 내에서만 선택
      if (selection_rules.use_limit_folder_max) {
        if (this._folderList != null && this._folderList.length > 0) {
          if (!isContainsSelectionTicket(this._selectedItems, newItem)) {
            if (selection_rules.limit_folder_max_number <= this._folderList[0])
              return false;
          }
        }
      }
      // 게임카드 10개 내에서만 선택
      if (selection_rules.use_limit_game_card_max) {
        if (selection_rules.limit_game_card_max_number < getGameCardCount(this._selectedItems, newItem))
          return false;
      }
      this._selectedItems.push(newItem);
    } else {
      this.fixedSelectedAllotData = this.fixedSelectedAllotData.filter(item => item !== newItem);
      this._selectedItems = this._selectedItems.filter(item => item !== newItem);
    }

    this.refreshSelectedAllotDataList();
    this.updateNewFolderNumber();
    this.refreshFolder(this.currentFolderNumber());
    return true;
  }

  clearSelection() {
    // 데이터 비우기
    this._selectedItems = [];
    this.fixedSelectedAllotData = [];
    this.selectedAllotData = [];
    this._currentFolderNumber = 0;
    this._folderList = null;

    this._onChanged();
  }

  _currentFolderNumber = 0;

  currentFolderNumber() {
    return Math.max(this._currentFolderNumber, 1);
  }

  refreshFolderWithCause(cause, folderNumber) {
    this.refreshFolder(folderNumber);
  }

  refreshSelectedAllotDataList() {
    let _selectedAllotData = [];
    let currentGameInfo;
    let currentGameAllotData;
    let prediction;
    let extraText;
    let extraHomeText;
    let isSingle;


    for (const itemKey of this._selectedItems) {
      currentGameInfo = _getGameInfoFromGameDataBySelectedItemKey(this.gameData, itemKey);

      if (currentGameInfo === null)
        continue;
      currentGameAllotData = _getGameAllotDataFromGameInfoBySelectedItemKey(currentGameInfo, itemKey);

      // 싱글게임인지 검사
      isSingle = currentGameAllotData.gameNo.indexOf("ⓢ") > 0;

      // extraText 게임유형 구분
      extraText = currentGameAllotData.extraText;
      if (extraText === "H") {
        extraText = isSingle ? "H*" : "H "; // 싱글이면 * 붙음
        if (currentGameAllotData.extra.indexOf("-") === 0) {
          extraHomeText = " - " + currentGameAllotData.extra.substring(1);
        } else {
          extraHomeText = " + " + currentGameAllotData.extra;
        }
      } else if (extraText === "SUM") {
        extraText = isSingle ? "⒮*" : "⒮ ";
        extraHomeText = "";

      } else if (extraText === "U/O") {
        extraText = isSingle ? "U*" : "U ";
        extraHomeText = currentGameAllotData.extra;
      } else if (extraText === "①") {
        extraHomeText = " " + extraText;
        extraText = isSingle ? "*" : "";
      } else if (extraText === "⑤") {
        extraHomeText = " " + extraText;
        extraText = isSingle ? "*" : "";
      } else {
        extraText = isSingle ? "*" : "";
        extraHomeText = "";
      }
      prediction = _getPredictionData(itemKey); // 예상 (승, 무, 패)
      // 선택한 게임 테이블에 추가
      _selectedAllotData.push({
        closingDate: currentGameInfo.closingDate,
        itemKey: itemKey,
        isSingle: isSingle,
        allotId: currentGameAllotData.allotId,
        category:currentGameAllotData.category,
        gameNumber: extraText + currentGameAllotData.gameNo.replace(/ⓢ/g, '').padStart(3, "0"),
        homeTeam: currentGameInfo.homeTeam + extraHomeText,
        awayTeam: currentGameInfo.awayTeam,
        prediction: prediction,
        rate: _getRateFromGameAllotDataByPrediction(currentGameAllotData, prediction)
      });
    }
    this.selectedAllotData = _selectedAllotData;
  }

  refreshFolder(folderNumber) {
    this._currentFolderNumber = folderNumber;

    if (this._selectedItems.length < folderNumber) {
      folderNumber = this._selectedItems.length;
    }

    let obj;
    let closingDate;
    let gameNumber;
    let selectGame;
    let currentGameTicket;

    let list = generateCombinations(this._selectedItems, folderNumber); // 선택한 경기로 만들 수 있는 모든 조합
    let _ticketData = []; // 조합으로 티켓 데이터 생성

    let currentAllotData;

    for (let index = 0; index < list.length; index++) {
      selectGame = [];
      obj = list[index];
      if (obj.length === 0)
        continue;
      closingDate = "2999-12-32";
      gameNumber = obj[0].split('_')[2].substr(-3);

      if (!_validateFixedAllotDataContains(obj, this.fixedSelectedAllotData)) {
        // 고정된 경기가 포함되어있지 않으면 넘어감
        continue;
      }
      for (const itemKey of obj) {
        currentAllotData = _getGameAllotDataFromSelectedAllotDataTable(this.selectedAllotData, itemKey);
        if (closingDate > currentAllotData.closingDate) {
          closingDate = currentAllotData.closingDate;
        }
        if (currentAllotData === null)
          continue;
        selectGame.push(currentAllotData);
      }

      if (_validateTicket(selectGame)) {
        currentGameTicket = {
          id: index, number: gameNumber, closingDate: closingDate, selectGame: [...selectGame]
        };
        currentGameTicket.totalRate = this.getRatingValue(currentGameTicket);
        _ticketData.push(currentGameTicket);
      }
    }
    //  this.fixedSelectedAllotData=_selectedAllotData;

    sortTicketData(_ticketData);

    this.ticketData = _ticketData;

    this._onChanged();
  }

  onUpdatedHandlers = [];
  onChangedHandlers = [];

  addOnUpdatedHandlers(handler) {
    if (this.onUpdatedHandlers.includes(handler)) {
      return;
    }
    this.onUpdatedHandlers.push(handler);
  }

  removeUpdatedHandlers(handler) {
    this.onUpdatedHandlers = this.onUpdatedHandlers.filter(h => h !== handler);
  }

  _onUpdated() {
    try {
      this.onUpdatedHandlers.forEach(handler => {
        try {
          if (handler == null)
            return;
          handler();
        } catch {
        }
      });
    } catch {
    }
  }

  addOnChangedHandler(handler) {
    if (this.onChangedHandlers.includes(handler)) {
      return;
    }
    this.onChangedHandlers.push(handler);
  }

  removeOnChangedHandler(handler) {
    this.onChangedHandlers = this.onChangedHandlers.filter(h => h !== handler);
  }

  _onChanged() {
    try {
      this.onChangedHandlers.forEach(handler => {
        try {
          if (handler == null)
            return;
          handler();
        } catch {
        }
      });
    } catch {
    }
  }

  loadGameDataList(cause) {
    // 새로고침해서 데이터 다시 받아오기
    this.refreshFolderWithCause(cause, this.currentFolderNumber());
    reloadGameDataFromWebServer(2);
  }

  getRatingValue(card) {
    // 경기 개수에 따른 배당 구하기
    let selectedAllotData = card.selectGame;
    if (selectedAllotData.length === 1)
      return selectedAllotData[0].rate;

    let ratingValue = 1;
    for (const item of selectedAllotData) {
      ratingValue = multiplyRate(ratingValue, item.rate);

    }
    // ratingValue = 2.01일 경우 *100 처리하면 200.9999로 나와서 + 0.001 처리
    ratingValue = Math.floor(ratingValue * 100 + 0.001) / 100;
    ratingValue = Math.ceil(ratingValue * 10) / 10;
    return ratingValue;
  }

  toggleFixedAllotId(id, selectionKey) {
    // 경기 고정에 따라 고정된 경기, 폴더 관리
    if (selectionKey === "승" || selectionKey === "U") {
      selectionKey = "w";
    } else if (selectionKey === "패" || selectionKey === "O") {
      selectionKey = "l";
    } else {
      selectionKey = "d";
    }
    selectionKey = id + "|" + selectionKey;

    if (this.fixedSelectedAllotData.includes(selectionKey)) {
      this.fixedSelectedAllotData = this.fixedSelectedAllotData.filter(item => item !== selectionKey);
      this.updateNewFolderNumber();
      this.refreshFolder(this.currentFolderNumber());
      this._onChanged();
      return;
    }

    this.fixedSelectedAllotData.push(selectionKey);
    this.updateNewFolderNumber();
    if (this.fixedSelectedAllotData.length > this.currentFolderNumber()) {
      this._currentFolderNumber = this.fixedSelectedAllotData.length;
    }
    this.refreshFolder(this.currentFolderNumber());
    this._onChanged();
  }

  isFixedAllotData(id, selectionKey) {
    if (selectionKey === "승" || selectionKey === "U") {
      selectionKey = "w";
    } else if (selectionKey === "패" || selectionKey === "O") {
      selectionKey = "l";
    } else {
      selectionKey = "d";
    }
    return this.fixedSelectedAllotData.includes(id + "|" + selectionKey)
  }


  commitFilter(currentFilterRule) {
    //필터 적용해서 필터드 데이터 리스트 세팅
    //neverLandGameData.gameData <- currentFilterRule ==>  neverLandGameData.filteredData
    neverLandGameData.currentFilterRule = currentFilterRule;
    if (neverLandGameData.isFilterChanged)
      return;
    neverLandGameData.isFilterChanged = true;
    // 한번에 여러번 반복 실행되지않게 막아두기
    setTimeout(neverLandGameData._delayedUpdate, 80);
  }

  _delayedUpdate() {
    neverLandGameData._onChanged();
  }

  getFilteredGameData() {
    if (neverLandGameData.currentFilterRule === null)
      return neverLandGameData.gameData;

    if (neverLandGameData.isFilterChanged) {
      neverLandGameData.isFilterChanged = false;
      neverLandGameData.filteredData = makeFilteredGameData(neverLandGameData.gameData, neverLandGameData.currentFilterRule);
    }
    return neverLandGameData.filteredData;
  }

  isFilteredGameAllotData(extraText) {
    if (neverLandGameData.currentFilterRule == null)
      return false;
    return neverLandGameData._isFilteredGameAllotData(neverLandGameData.currentFilterRule['type'], extraText);
  }

  _isFilteredGameAllotData(currentFilterRule, extraText) {
    // 필터 체크 테이블 게임유형 지정
    if (currentFilterRule.includes("전체"))
      return false;
    if (extraText === null) {
      extraText = "일반";
    }
    if (extraText === "null") {
      extraText = "일반";
    }
    if (extraText === "무") {
      extraText = "일반";
    }
    if (extraText === "H") {
      extraText = "핸디캡";
    }
    if (extraText === "U/O") {
      extraText = "언더오버";
    }
    if (extraText === "SUM") {
      extraText = "SUM";
    }
    if (extraText === "①") {
      extraText = "승1패";
    }
    if (extraText === "⑤") {
      extraText = "승5패";
    }
    if (currentFilterRule.includes(extraText))
      return false;
    return true;
  }

  timerId = -1;

  beginAutoUpdate() {
    // 20초 마다 게임데이터 불러오기
    if (this.timerId !== -1)
      return;
    this.timerId = setInterval(() => {
      reloadGameDataFromWebServer(20);
    }, 1000);
  }

  endAutoUpdate() {
    if (this.timerId === -1)
        // 정지되어있으면 두번 정지안되게
      return;
    try {
      clearTimeout(this.timerId);
    } catch {
    }
    this.timerId = -1;
  }

  convertGameDataToTicket(content) {
    // console.log('content->convertGameDataToTicket',content);
    //
    //
    //
    // let gameNoText;
    // let isSingle;
    // for(let c of content){
    //
    //    for(let s of c.selectGame) {
    //      let extraText = s.extraText;
    //      let extraHomeText ="";
    //      gameNoText = s.gameNumber;
    //
    //      if (s.gameNumber.includes("ⓢ")) {
    //        isSingle = true;
    //        gameNoText = gameNoText.replace("ⓢ", "");
    //      }
    //      if (extraText === "H") {
    //        extraText = isSingle ? "H*" : "H "; // 싱글이면 * 붙음
    //        if (s.draw.indexOf("-") === 0) {
    //          extraHomeText = " - " + s.draw.substring(1);
    //        } else {
    //          extraHomeText = " + " + s.draw;
    //        }
    //      } else if (extraText === "U/O") {
    //        extraText = isSingle ? "U*" : "U ";
    //        extraHomeText = s.draw;
    //      } else if (extraText === "⑤") {
    //        extraHomeText = " " + extraText;
    //        extraText = isSingle ? "*" : "";
    //      } else {
    //        extraText = isSingle ? "*" : "";
    //        extraHomeText = "";
    //      }
    //
    //      gameNoText = gameNoText.padStart(3, "0");
    //
    //      s.gameNumber = extraText + gameNoText;
    //      s.homeTeam = s.homeTeam + extraHomeText;
    //    }
    // }
    return content;
  }

  getTicketCardsByJsonOrder(orderDataList) {

    let ticketDataList = [];

    if (orderDataList === null)
      return ticketDataList;
    let _gameData = neverLandGameData.gameData;
    let index = 1;

    function errorDataCheck(thisTicket) {
      if (thisTicket.selectedGame.length > 10) {
        thisTicket.error = "폴더 갯수 초과";
        return;
      }
      if (thisTicket.selectedGame.length === 1) {
        if (!thisTicket.selectedGame[0].gameNo.includes("*")) {
          thisTicket.error = "싱글외의 단폴더 불가";
          return;
        }
      }
      let sameGames = [];
      for (let item of thisTicket.selectedGame) {
        if (sameGames.includes(item.title)) {
          thisTicket.error = "동일게임 선택불가";
          item.title = item.title + " X";
          continue;
        }
        sameGames.push(item.title);
      }
    }

    for (let data of orderDataList) {
      let thisTicket = {
        index: index++,
        amount: 0,
        error: null,
        selectedGame: []
      };
      if (data.error) {
        thisTicket.error = data.error;
      } else {
        thisTicket.amount = data.amount;

        data.allotSelections.forEach((selections) => {
              let realGameData = null;
              let realGameAllot = null;
              let thisId = '_' + selections.id.toString().padStart(4, '0');
              for (const data of _gameData) {
                for (const allot of data.data) {
                  if (allot.allotId.endsWith(thisId)) {
                    realGameData = data;
                    realGameAllot = allot;
                    break;
                  }
                }
                if (realGameAllot !== null)
                  break;
              }
              if (realGameAllot === null) {
                thisTicket.error = "등록불가 항목 포함";
                thisTicket.selectedGame.push({
                  title: "확인 불가",
                  allotId: selections.id, predicate: selections.selection, predicateText: '?',
                  allotCode: selections.id, gameNo: '-', rate: '?'
                })
                return;
              }

              let thisPredicateText;
              let extraText = "";
              let selectionCode = selections.selection;


              if (realGameAllot.extraText === 'SUM') {
                thisPredicateText = selections.selection === 'i' ? '홀' :
                    (selections.selection === 'p' ? '짝' : '오류');

                selectionCode = selections.selection === 'i' ? 'w' :
                    (selections.selection === 'p' ? 'l' : '오류');
                extraText = "SUM";
              } else if (realGameAllot.extraText === 'U/O') {
                thisPredicateText = selections.selection === 'u' ? '언더' :
                    (selections.selection === 'o' ? '오버' : '오류');

                selectionCode = selections.selection === 'u' ? 'w' :
                    (selections.selection === 'o' ? 'l' : '오류');
                extraText = "U";
              } else {
                thisPredicateText = selections.selection === 'w' ? '승' :
                    (selections.selection === 'l' ? '패' : (selections.selection === 'd' ? '무' : '오류'));
                if (realGameAllot.extraText === 'H')
                  extraText = "H";
              }
              let thisRate = selectionCode === 'w' ? realGameAllot.win :
                  (selectionCode === 'l' ? realGameAllot.lose : realGameAllot.draw);

              if (realGameData.state !== "발매중") {
                thisTicket.error = "미발매상태 항목 포함";
              } else if (thisPredicateText === "오류") {
                thisTicket.error = "항목선택 오류 포함";
              } else if (thisRate === "0.00") {
                thisTicket.error = "선택불가 항목 포함";
                thisPredicateText = "불가";
                thisRate = "x";
              }
              if (realGameAllot.extraText === "①") {
                extraText = "①";
              } else if (realGameAllot.extraText === "⑤") {
                extraText = "⑤";
              }
              thisTicket.selectedGame.push({
                title: (realGameData.state === "null" ? "미발매" : realGameData.state) + " ) " + realGameData.category + "|" + realGameData.homeTeam + " vs " + realGameData.awayTeam,
                allotId: selections.id, predicate: selectionCode, predicateText: thisPredicateText,
                allotCode: realGameAllot.allotId,
                gameNo: (extraText + (realGameAllot.gameNo.includes("ⓢ") ? "*" : " ") + (realGameAllot.gameNo.replace("ⓢ", "")).padStart(3, "0")).trim(),
                rate: thisRate
              })
            }
        );
        if (data.amount < 1000) {
          thisTicket.error = "최소금액미달(1,000원)";
        }
      }
      if (!thisTicket.error) {
        errorDataCheck(thisTicket);
      }
      ticketDataList.push(thisTicket);
    }
    return ticketDataList;
  }
}

let lastRequestDate = null;
let informationTime = null;


function getCurrentFormattedDate() {
  const date = new Date();

  // 년도, 월, 일, 시, 분, 초 추출
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // 포맷된 날짜 문자열 반환
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


function _commitGameData(data, checkDoubleHeader) {
  if (data === null)
    return false;
  const currentDate = getCurrentFormattedDate();
  let changedCount = 0;
  let gameKeyList = {};
  let temp;

  for (let g of data) {
    // 더블체크 확인해서 같은날에 같은 홈팀 vs 원정팀인지 카운트
    if (checkDoubleHeader) {
      let key = g.gameplayDate.split(' ')[0].trim() + ' = ' + g.homeTeam + ' vs ' + g.awayTeam;
      temp = gameKeyList[key];
      if (temp) {
        gameKeyList[key] = temp++;
      } else {
        gameKeyList[key] = 1;
      }
    }
    // 경기시간이랑 현재시간 체크해서 발매종료 상태로 바꾸기
    if (g.state === '발매중') {
      if (g.gameplayDate < currentDate) {
        g.state = '발매종료';
        changedCount++;
      }
    }
  }
  // 카운트가 1이상이면 더블헤더
  if (checkDoubleHeader) {
    for (let g of data) {
      let key = g.gameplayDate.split(' ')[0].trim() + ' = ' + g.homeTeam + ' vs ' + g.awayTeam;
      g.isDoubleHeader = gameKeyList[key] > 1;
    }
  }

  gameKeyList = null;
  neverLandGameData.gameData = data;

  if (changedCount > 0) {
    // 경기 변경 여부 체크
    try {
      __cleanSelectionItemIsExpired(currentDate);
    } catch (e) {
      console.log('_commitGameData', e);
    }
    console.log('__cleanSelectionItemIsExpired' + changedCount, neverLandGameData._selectedItems);
    return true;
  }
  return false;
}

function __cleanSelectionItemIsExpired(currentDate) {
  // 선택한 경기중에서 화면에서 없어진경기(시작한 경기)가 있으면 제외하기
  let data = neverLandGameData.gameData;
  if (data === null)
    return;
  let _si = neverLandGameData._selectedItems;
  let _newSi = [];
  if (data.length > 0) {
    for (let d of data) {
      if (d.closingDate < currentDate) {
        continue;
      }
      for (let allot of d.data) {
        for (let _s of _si) {
          if (_s.includes(allot.id + "|")) {
            _newSi.push(_s);
          }
        }
      }
    }
    neverLandGameData._selectedItems = _newSi;
    return;
  }
  neverLandGameData._selectedItems = [];

}


function reloadGameDataFromWebServer(delayTime) {
  // 서버에서 게임데이터 받아오기
  let currentDate = new Date();
  if (lastRequestDate !== null && (currentDate - lastRequestDate) / 1000 < delayTime) {
    if (_commitGameData(neverLandGameData.gameData, false)) {
      neverLandGameData._onUpdated();
      neverLandGameData._onChanged();
    }
    return false;
  }
  lastRequestDate = currentDate;
  //   let { REACT_APP_GAME_DATA_API_URL } = process.env;
  //
  // if (informationTime != null) {
  //   // 처음 데이터받아온 후 url 뒤에 informationTime 붙여서 보내기
  //   // 서버 informationTime 일치하지 않을 때 다시 데이터 받을 수 있게
  //   REACT_APP_GAME_DATA_API_URL = REACT_APP_GAME_DATA_API_URL + "?it=" + informationTime;
  // }

  if (informationTime === null) {
    informationTime = "unknown";
  }

  try {

    // let userId = accountInfo.accountId();
    // let sessionCode = accountInfo.accountSessionCode();

    getFetcher().get(accountInfo.makeGetRequest(LIST_OF_GAME_DATA_API) + `&updateDateTime=${informationTime}`)
        // .then(res => {
        //   if (res === undefined)
        //     return;
        //   if (!res.ok) {
        //     throw new Error(`서버 응답 오류: ${res.status} ${res.statusText}`);
        //   }
        //   let json = res.json();
        //   if (json === undefined)
        //     return null;
        //   return json;
        // })
        .catch(reason => {
          if (reason === undefined)
            return;
          if (reason instanceof TypeError && reason.message === 'Failed to fetch') {
            console.log('네트워크 연결이 끊어졌습니다.');
            return;
          }
          console.log('Fetch 오류:', reason);
        })
        .then(res => {
          if (res === undefined) return;
          let data = res.data;

          if (data === null)
            return;

          if (data === undefined)
            return;

          if (data.result.updated === true) {
            // 게임 데이터가 업데이트되면 상태값도 업데이트
            _commitGameData(data.result.gameInfo || [], true);
            if (neverLandGameData.filteredData !== null)
              neverLandGameData.isFilterChanged = true;

            let prevGameRound = neverLandGameData.gameRound;
            // 회차 바뀌면 선택한경기 초기화
            if (prevGameRound === null || prevGameRound !== data.result.tag.gameRound) {
              neverLandGameData._selectedItems = [];
              neverLandGameData.gameRound = data.result.tag.gameRound;
            }
            neverLandGameData.filterOptions = data.result.tag.filters;
            informationTime = data.result.tag.informationTime;
            neverLandGameData._onUpdated();
            neverLandGameData._onChanged();
          } else {
            if (_commitGameData(neverLandGameData.gameData, false)) {
              neverLandGameData._onUpdated();
              neverLandGameData._onChanged();
            }
            // console.log('data [isUpdated:false]', data);
          }
        });
  } catch (error) {
    console.log('Fetch 오류:', error);
    return false;
  }
  return true;
}

function _getGameAllotDataFromSelectedAllotDataTable(selectedAllotData, itemKey) {
  for (const data of selectedAllotData) {
    if (data.itemKey === itemKey) {
      return data;
    }
  }
  return null;
}

function _getPredictionData(itemKey) {
  let pk = itemKey.split('|')[1];
  if (pk === "w")
    return "승";
  if (pk === "l")
    return "패";
  if (pk === "d")
    return "무";
  return "-";
}

function _validateTicket(selectedItems) {
  if (selectedItems.length === 1) {
    if (!selectedItems[0].isSingle)
      return false;
  }
  let currentGameId;
  let gameId = [];
  for (const s1 of selectedItems) {
    currentGameId = s1.allotId.substring(0, s1.allotId.length - 5);
    if (gameId.includes(currentGameId))
      return false;
    gameId.push(currentGameId);
  }
  return true;
}

function _getRateFromGameAllotDataByPrediction(gameAllotData, prediction) {
  // 배당률 가져오기
  if (prediction === "승")
    return gameAllotData.win;
  if (prediction === "패")
    return gameAllotData.lose;
  if (prediction === "무")
    return gameAllotData.draw;
  return "-";
}

function _getGameInfoFromGameDataBySelectedItemKey(gameDataList, itemKey) {
  for (const item of gameDataList) {
    if (itemKey.indexOf(item.dataId + "_") === 0) {
      return item;
    }
  }
  return null;
}

function _getGameAllotDataFromGameInfoBySelectedItemKey(gameInfo, itemKey) {
  for (const item of gameInfo.data) {
    if (itemKey.indexOf(item.allotId + "|") === 0) {
      return item;
    }
  }
  return null;
}

function generateCombinations(arr, n) {
  const result = [];

  function helper(current, start) {
    if (current.length === n) {
      result.push([...current]);
      return;
    }

    for (let i = start; i < arr.length; i++) {
      current.push(arr[i]);
      helper(current, i + 1);
      current.pop();
    }
  }

  helper([], 0);
  return result;
}

function _validateFixedAllotDataContains(thisAllotDataList, fixedSelectedAllotDataList) {
  for (const fixedAllotDataListElement of fixedSelectedAllotDataList) {
    if (!thisAllotDataList.includes(fixedAllotDataListElement)) {
      return false;
    }
  }
  return true;
}

const neverLandGameData = new NeverLandGameData();
export default neverLandGameData;