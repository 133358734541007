import Sidebar from "../../components/Sidebar";
import React, {Fragment, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/locale";
import DatePickerInput from "../../components/DatePickerInput";
import ListSelectBox from "../../components/ListSelectBox";
import ReportUpperTable from "../../components/Table/ReportUpperTable";
import ReportLowerTable from "../../components/Table/ReportLowerTable";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo, lastMonth, lastWeek, today, yesterday} from "../../constants/constants";
import {
  STORE_AGGREGATE_LIST_API,
  STORE_AGGREGATE_TOTAL_API
} from "../../constants/api_constants";
import {toast} from "react-toastify";
import Pagination from "../../components/Pagination/Pagination";
import {roundDefaultList, roundInfo} from "../ticketListPage/base/BaseTicketListPage";
import ListSelectBoxWithDefaultData from "../../components/ListSelectBoxWithDefaultData";
import dayjs from "dayjs";
import {FOLDER_OPTION, REPORT_UPPER_TABLE_HEADER, searchTypeOption} from "../../constants/component_data";
import ReportUpperCard from "../../components/Card/ReportUpperCard";
import ReportLowerCard from "../../components/Card/ReportLowerCard";
import {useNavigate} from "react-router-dom";
import neverLandUtils from "../../utils/NeverLandUtils";
import neverLandGameData from "../../utils/NeverLandGameData";
import {MAIN_PAGE} from "../../constants/page_constants";

const requestStatus = [
  {id: 2, name: '요청승인', value: 'status2'},
  {id: 1, name: '승인 + 출력', value: 'status1'},
  {id: 3, name: '출력', value: 'status3'},
]
export const searchOption = [
  {id: 'round', name: '회차검색'},
  {id: 'date', name: '기간검색(의뢰일)'},
]

const CustomerReportPage = () => {
  const [isFilterRound, setIsFilterRound] = useState(true);  // 조회조건 : 회차 || 날짜
  const [selectedRound, setSelectedRound] = useState(roundDefaultList[0]); // 회차
  const [status, setStatus] = useState(requestStatus[0]); // 의뢰 상태
  const [startDate, setStartDate] = useState(new Date()); // DatePicker 시작 날짜
  const [endDate, setEndDate] = useState(new Date()); // DatePicker 종료 날짜
  const [formatDate, setFormatDate] = useState(() => dayjs(today).format('YYYYMMDD') + "-" + dayjs(today).format('YYYYMMDD')); // 검색할 날짜
  const [searchNickname, setSearchNickname] = useState(''); // 닉네임 검색어
  const [searchMemo, setSearchMemo] = useState(''); // 메모 검색어
  const [searchType, setSearchType] = useState(searchTypeOption[0])  // 검색 조건 (일치, 포함)
  const [folder, setFolder] = useState(FOLDER_OPTION[0]); // 폴더
  const [upperTableBody, setUpperTableBody] = useState({});
  const [lowerTableBody, setLowerTableBody] = useState([]);
  const [alignInfo, setAlignInfo] = useState('');
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = 30;

  // 기간 검색 관련 데이트 피커, 날짜 변환
  const dateFilterButton = [
    {label: '당일', onClick: () => handleDateChange(today, today)},
    {label: '전일', onClick: () => handleDateChange(yesterday, yesterday)},
    {label: '일주일', onClick: () => handleDateChange(lastWeek, today)},
    {label: '한달', onClick: () => handleDateChange(lastMonth, today)},
  ]
  const handleDateChange = (startDate, endDate) => {
    // 기간검색 날짜형식 변환
    // TODO : 날짜형식 getDisplayTextByDate() 함수로 바꾸기
    let _startDate = dayjs(startDate).format('YYYYMMDD');
    let _endDate = dayjs(endDate).format('YYYYMMDD');
    setStartDate(startDate);
    setEndDate(endDate);
    setFormatDate(_startDate + "-" + _endDate);
  };

  // useEffect(() => {
  //   getTableData();
  // }, []);

  const [isFirstLoading, setIsFirstLoading] = useState(false);

  useEffect(() => {
    if (!isFirstLoading) {
      getTableData(); // getTableData 최초 1회 실행
      setIsFirstLoading(true); // 최초 실행 여부 설정
    }
  }, []);

  useEffect(() => {
    if (isFirstLoading)
      getLowerTableBodyData();
  }, [currentPage, alignInfo]);

  const getTableData = () => {
    getUpperTableBodyData();
    getLowerTableBodyData();
  }

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    let jsonString = Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
    return jsonString;

  }

  const handleSearchOptionSelect = (selectOption) => {
    // 기간검색 || 회차검색
    if (selectOption.id === 'round') {
      setIsFilterRound(true);
      // 회차 검색이면 조회날짜 오늘
    } else {
      setIsFilterRound(false);
      // 기간 검색이면 현재 회차
      setSelectedRound(roundDefaultList[0]);
    }
  }

  const getSearchCondition = () => {
    let _memo;
    let _nickName;
    if (searchType.name === '정확히 일치') {
      _memo = "★" + searchMemo + "★"
      _nickName = "★" + searchNickname + "★"
    } else if (searchType.name === '포함') {
      _memo = searchMemo
      _nickName = searchNickname
    }
    if (isFilterRound) {
      return `status:${status.value}` + '▼' + `nickname:${_nickName}` + '▼' + `memo:${_memo}` + '▼' + `folder:${folder.id}`
    }
    return `status:${status.value}` + '▼' + `date:${formatDate}`
        + '▼' + `nickname:${_nickName}` + '▼' + `memo:${_memo}` + '▼' + `folder:${folder.id}`
  }

  const getGameRound = (roundData) => {
    // 회차정보
    if (roundData === 'current') return "";
    let round = roundData.substring(7);
    return roundData.substring(5, 7) + "년도 " + ((round.startsWith('0') ? round.substring(1) : round)) + " 회차";
  }


  const getUpperTableBodyData = () => {
    // 상단 집계 테이블 데이터 가져오기
    getFetcher().get(accountInfo.makeGetRequest(STORE_AGGREGATE_TOTAL_API)
        + `&roundCode=${selectedRound.data.code}&searchCondition=${getSearchCondition()}`
    ).then(res => {
      const {header, result, custom} = res.data;


      if (header.type === 'success') {
        setUpperTableBody(result.total);

        if (custom !== undefined && custom.items !== undefined && custom.items.length > 0) {
          let tempName;
          for (let item of custom.items) {
            // 데이터에서 회차 셀렉트 리스트 받아와서 설정
            if (item.key === 'rounds') {
              let newRoundList = [];
              newRoundList.push(roundDefaultList[0]);

              let index = 2;
              for (let round of item.value) {
                tempName = getGameRound(round).trim();
                if (tempName.length === 0) continue;
                newRoundList.push({id: index++, name: tempName, data: {code: round}});
              }
              if (newRoundList.length > 0) {
                roundInfo.list = newRoundList;
                setSelectedRound(newRoundList[1]);
              }
            }
          }
        }
        if (selectedRound && roundInfo.list.length === 1) {
          setSelectedRound(roundInfo.list[0]);
        }
      } else toast.error(header.message);
    }).catch(err => toast.error(err));
  }

  const getLowerTableBodyData = () => {
    // 하단 리스트 테이블 데이터 가져오기
    let localAlignInfo = alignInfo;
    if (!isFirstLoading) {
      localAlignInfo = jsonDataToString(accountInfo.getAlignDataList("ReportLowerTable"));
    }
    getFetcher().get(accountInfo.makeGetRequest(STORE_AGGREGATE_LIST_API)
        + `&roundCode=${selectedRound.data.code}&searchCondition=${getSearchCondition()}&pageNumber=${currentPage}&pageSize=${pageSize}&searchOrder='${localAlignInfo}`
    ).then(res => {
      const {header, result} = res.data;
      if (header.type === 'success') {
        let _list = result.list;

        let currentPageNumber = _list.pageable.pageNumber + 1;
        if (_list.content.length === 0) {
          currentPageNumber = 1;
        }
        setCurrentPage(currentPageNumber);
        setTotalPage(_list.totalPages);
        setLowerTableBody(_list.content);
      } else toast.error(header.message);
    }).catch(err => toast.error(err));
  }

  return (
      <>
        <Sidebar/>
        <main className="lg:pl-64">
          <div className="sm:-mx-0 sm:rounded-lg sm:min-h-[43rem]">
            {/* <-- 검색조건 라인 --> */}

            {/* <-- PC 사이즈 --> */}
            <div className="hidden xl:flex mx-10 lg:my-8 flex-wrap flex-row justify-start text-sm items-center">
              <ListSelectBox listData={requestStatus} onChange={setStatus}/>
              <ListSelectBox listData={searchOption} onChange={handleSearchOptionSelect}/>
              {isFilterRound && (
                  // 회차검색
                  <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                                onChange={setSelectedRound}/>
              )}

              {!isFilterRound && (
                  // 기간검색
                  <div className="flex flex-row items-center">
                    {dateFilterButton.map((data) => (
                        <button
                            key={data.label}
                            onClick={data.onClick}
                            className="whitespace-nowrap mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                        >
                          {data.label}
                        </button>
                    ))}
                    <div
                        className="mr-3 flex flex-row text-sm justify-between px-5 py-2 ring-1 ring-gray-400 rounded-3xl text-center">
                      <DatePicker
                          locale={ko}
                          dateFormat='yyyy.MM.dd'
                          selected={startDate}
                          onChange={date => handleDateChange(date, endDate)}
                          customInput={<DatePickerInput/>}
                      />
                      <span className="mx-2"> ~ </span>
                      <DatePicker
                          locale={ko}
                          dateFormat='yyyy.MM.dd'
                          selected={endDate}
                          onChange={date => handleDateChange(startDate, date)}
                          customInput={<DatePickerInput/>}
                      />
                    </div>
                  </div>
              )}

              <ListSelectBox listData={FOLDER_OPTION} onChange={setFolder}/>

              <input
                  placeholder="닉네임"
                  onChange={(e) => setSearchNickname(e.target.value)}
                  className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-400 rounded-3xl focus:ring-indigo-500"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') getTableData();
                  }}
              />
              <input
                  placeholder="메모"
                  onChange={(e) => setSearchMemo(e.target.value)}
                  className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-400 rounded-3xl focus:ring-indigo-500 mr-2"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') getTableData();
                  }}
              />
              <ListSelectBox listData={searchTypeOption} onChange={setSearchType}/>
              <button
                  onClick={getTableData}
                  className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                검색
              </button>

            </div>

            {/* <-- 모바일 사이즈 검색 라인 --> */}
            <div
                className="xl:hidden lg:mt-3 mx-2 flex flex-wrap flex-row justify-start md:justify-center text-sm items-center">
              <div className="flex flex-row">
                <ListSelectBox listData={requestStatus} onChange={setStatus}/>
                <ListSelectBox listData={FOLDER_OPTION} onChange={setFolder}/>
                <ListSelectBox listData={searchOption} onChange={handleSearchOptionSelect}/>
              </div>
              {isFilterRound && (
                  // 회차검색
                  <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                                onChange={setSelectedRound}/>
              )}

              {!isFilterRound && (
                  // 기간검색
                  <div className="flex flex-col md:flex-row items-center mt-2">
                    <div className="flex flex-row">
                      {dateFilterButton.map((data) => (
                          <button
                              key={data.label}
                              onClick={data.onClick}
                              className="whitespace-nowrap mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                          >
                            {data.label}
                          </button>
                      ))}
                    </div>
                    <div
                        className="mt-2 md:mt-0 mr-3 flex flex-row text-sm justify-between px-5 py-2 ring-1 ring-gray-400 rounded-3xl text-center">
                      <DatePicker
                          locale={ko}
                          dateFormat='yyyy.MM.dd'
                          selected={startDate}
                          onChange={date => handleDateChange(date, endDate)}
                          customInput={<DatePickerInput/>}
                      />
                      <span className="mx-2"> ~ </span>
                      <DatePicker
                          locale={ko}
                          dateFormat='yyyy.MM.dd'
                          selected={endDate}
                          onChange={date => handleDateChange(startDate, date)}
                          customInput={<DatePickerInput/>}
                      />
                    </div>
                  </div>
              )}
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-row">
                  <input
                      placeholder="닉네임"
                      onChange={(e) => setSearchNickname(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') getTableData();
                      }}
                      className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mt-2"
                  />
                  <input
                      placeholder="메모"
                      onChange={(e) => setSearchMemo(e.target.value)}
                      className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') getTableData();
                      }}
                  />
                  <ListSelectBox listData={searchTypeOption} onChange={setSearchType}/>
                </div>
                <button
                    onClick={getTableData}
                    className="whitespace-nowrap rounded-md bg-blue-900 w-full md:w-16 mt-2 px-3 py-2 text-sm font-semibold text-white shadow-sm">
                  검색
                </button>
              </div>
            </div>

            {/* <- PC 화면 테이블 형식 -> */}
            <div className="hidden xl:block">
              {/* <- 위쪽 테이블 -> */}
              <ReportUpperTable upperTableBodyData={upperTableBody} tableHeader={REPORT_UPPER_TABLE_HEADER}/>
              {/* <- 아래쪽 테이블 -> */}
              <ReportLowerTable lowerTableBodyData={lowerTableBody}
                                setAlignData={(alignInfo) => setAlignInfo(alignInfo)}/>
            </div>

            {/*<- 화면 작을 때 카드형식 -> */}
            <div className="xl:hidden flex flex-col justify-center">
              <ReportUpperCard upperTableBodyData={upperTableBody} tableHeader={REPORT_UPPER_TABLE_HEADER}/>
            </div>
            <div className="xl:hidden flex justify-center">
              <ReportLowerCard lowerTableBodyData={lowerTableBody}
                               setAlignData={(alignInfo) => setAlignInfo(alignInfo)}/>
            </div>
          </div>

          {totalPage > 1 && <div className="mt-10 px-10">
            {/* 페이지네이션 */}
            <Pagination
                totalPage={totalPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
          </div>
          }
        </main>
      </>
  )
}
export default CustomerReportPage;