import Sidebar from "../components/Sidebar";
import MyPageHeader from "../components/Header/MyPageHeader";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {useEffect, useState} from "react";
import AddFavoriteStoreModal from "../components/Modal/AddFavoriteStoreModal";
import fetcher, {getFetcher} from "../utils/fetcher";
import {accountInfo} from "../constants/constants";
import {DELETE_MY_FAVORITE_STORE_API, GET_MY_STORE_LIST_API, SET_DEFAULT_STORE_API} from "../constants/api_constants";
import {toast} from "react-toastify";
import {RiCheckDoubleLine} from "react-icons/ri";

const MyFavoriteStoreListPage = () => {
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [myStoreList, setMyStoreList] = useState([]);

  useEffect(() => {
    getMyFavoriteStoreInfo();
  }, [showAddStoreModal])

  const getMyFavoriteStoreInfo = () => {
    // <-- 내 지점 정보 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(GET_MY_STORE_LIST_API))
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            setMyStoreList(result.myStoreList);
          } else {
            toast.error('지점 목록을 불러오는데 실패하였습니다.')
          }
        }).catch(err => toast.error(err.message));
  }

  const setDefaultStore = (storeCode, storeTel) => {
    // <-- 기본 지점으로 지정 -->
    let _encodeStoreCode = encodeURIComponent(storeCode);

    getFetcher().post(SET_DEFAULT_STORE_API, {
      user: accountInfo.makeJson(),
      storeCode: _encodeStoreCode,
      phoneNumber: storeTel
    })
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success("기본지점으로 지정 되었습니다.");
            accountInfo.updateDefaultStoreCode(_encodeStoreCode);
            getMyFavoriteStoreInfo();
          } else {
            toast.error(message);
          }
        })
  }

  const deleteMyFavoriteStore = (storeCode) => {
    // <-- 즐겨찾기 지점 삭제 -->
    // TODO: 바로삭제되게 말고 모달로 한번더 물어보고 삭제하기
    const encodedStoreCode = encodeURIComponent(storeCode);
    getFetcher().delete(DELETE_MY_FAVORITE_STORE_API + `/${accountInfo.accountId()}/${accountInfo.accountSessionCodeUrlEncode()}/${encodedStoreCode}`)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success(message);
            getMyFavoriteStoreInfo();
          } else toast.warning(message);
        }).catch(err => toast.error(err.message));
  }

  return (
      <>
        {showAddStoreModal && <AddFavoriteStoreModal setShowAddModal={setShowAddStoreModal}/>}
        <div>
          <Sidebar/>
          <div className="lg:pl-64">
            <MyPageHeader/>
            <main>
              <div className="lg:mt-16 sm:mx-4 h-full lg:px-52 border-t border-white/10 py-6 sm:py-16">
                <div className="flex flex-row items-center justify-between">
                  <div className="ml-4 sm:ml-0 flex flex-col whitespace-nowrap">
                    <h2 className="text-xl font-semibold text-gray-90 ">내 지점 관리</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      즐겨찾는 지점을 관리합니다.
                    </p>
                  </div>

                  <button
                      onClick={() => setShowAddStoreModal(true)}
                      type="button"
                      className="mr-24 ml-10 inline-flex items-center gap-x-1.5 whitespace-nowrap rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800"
                  >
                    <PlusCircleIcon className="-ml-0.5 h-5 w-5"/>
                    지점 추가
                  </button>
                </div>


                <table className="mt-12 w-full whitespace-nowrap text-left">
                  <colgroup>
                    <col className="w-full sm:w-2/12"/>
                    <col className="lg:w-2/12"/>
                    <col className="lg:w-4/12"/>
                    <col className="lg:w-2/12"/>
                    <col className="lg:w-2/12"/>
                  </colgroup>
                  <thead className="border-b-2 border-gray-700 text-sm leading-6 text-gray-900">
                  <tr>
                    <th scope="col" className="py-2 pl-2 sm:pr-5 font-semibold sm:pl-6 lg:pl-2 text-center">
                      기본지점
                    </th>
                    <th scope="col" className="py-2 pl-2 pr-5 sm:pr-10 font-semibold sm:pl-6 lg:pl-2 text-center">
                      구분
                    </th>
                    <th scope="col" className="py-2 pr-2 font-semibold sm:table-cell text-center">
                      지점명
                    </th>
                    <th scope="col" className="py-2 pr-2 font-semibold sm:table-cell text-center">
                      지점코드
                    </th>
                    <th scope="col" className="hidden py-2 pl-8 pr-8 font-semibold md:table-cell lg:pr-20 text-center">
                      주소
                    </th>
                    <th scope="col"
                        className="hidden py-2 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8 text-center">
                      연락처
                    </th>
                    <th scope="col"
                        className="hidden py-2 pr-4 font-semibold sm:pr-8 sm:text-left lg:pr-20 text-center">
                      {/* 버튼 */}
                    </th>
                  </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-300 text-gray-900">
                  {myStoreList?.map((store) => (
                      <tr key={store.code} className="hover:bg-blue-50">
                        <td className="pl-8 sm:pl-12">
                          {store.defaultStore ? <RiCheckDoubleLine className="w-5 h-5 text-green-600"/> : null}
                        </td>

                        <td className="pr-8 sm:pl-8 text-center">
                          <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                            {store.state === 'regist' && (
                                <span
                                    className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700"> 승인대기중 </span>
                            )}
                            {store.state === 'confirmed' && (
                                <span
                                    className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700"> 승인 </span>
                            )}
                            {store.state === 'deferring' && (
                                <span
                                    className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800"> 보류 </span>
                            )}
                            {store.state === 'banned' && (
                                <span
                                    className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"> 거부 </span>
                            )}
                          </div>
                        </td>
                        <td className="py-4 pl-0 pr-4 sm:table-cell text-sm sm:pr-2 text-center">
                          {store.name}
                        </td>
                        <td className="py-4 pl-0 pr-4 sm:table-cell text-sm sm:pr-2 text-center">
                          {store.code}
                        </td>
                        <td className="hidden py-4 pl-8 pr-8 text-sm leading-6 md:table-cell lg:pr-20 text-center">
                          {store.address}
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 text-sm leading-6 sm:table-cell sm:pr-6 lg:pr-8 text-center">
                          {store.tel}
                        </td>
                        <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20 text-center">
                          {!store.defaultStore &&
                              <div className="flex flex-row">
                                <button
                                    onClick={() => setDefaultStore(store.code, store.tel)}
                                    className="rounded bg-green-500 text-white px-2 py-1 text-xs font-semiboldshadow-sm font-semibold">
                                  기본지점 지정
                                </button>

                                <button
                                    type="button"
                                    onClick={() => deleteMyFavoriteStore(store.code)}
                                    className="ml-2 rounded bg-red-500 text-white px-2 py-1 text-xs font-semiboldshadow-sm font-semibold">
                                  지점 삭제
                                </button>
                              </div>
                          }
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </main>
          </div>
        </div>
      </>
  )
}
export default MyFavoriteStoreListPage;